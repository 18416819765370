
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index2t9lpOtAVJMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/audit/index.vue?macro=true";
import { default as indexA66hVTIyoXMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/authentication/index.vue?macro=true";
import { default as editGAQB6MxBfqMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/automations/[id]/edit.vue?macro=true";
import { default as indexgaX51CHHU1Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/automations/index.vue?macro=true";
import { default as newLQC623ZDa9Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/automations/new.vue?macro=true";
import { default as indexR3oi0Au7wZMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/billing/index.vue?macro=true";
import { default as indexAzFUggvgqHMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/calculationVariables/index.vue?macro=true";
import { default as indexIJrT6wW8MYMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/categories/index.vue?macro=true";
import { default as callbackUP082BVtM6Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/box/callback.vue?macro=true";
import { default as indexZrTO2nuvnaMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/box/index.vue?macro=true";
import { default as redirectHykW2x1KHNMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/box/redirect.vue?macro=true";
import { default as indexbtUpeFVjybMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/chatwork/index.vue?macro=true";
import { default as indexXqbvwDwq1wMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue?macro=true";
import { default as indexa597tUrBXNMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue?macro=true";
import { default as indexfXRD82HUSCMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue?macro=true";
import { default as results0juFc56gIIMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue?macro=true";
import { default as indexRuPdACRVbiMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue?macro=true";
import { default as callbackrgBk3TTqWMMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue?macro=true";
import { default as indexJU49dQBEBzMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue?macro=true";
import { default as indexojFGSrBqPVMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue?macro=true";
import { default as callbackVJoLRbrvyKMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/slack/callback.vue?macro=true";
import { default as indexZsoAWmiErlMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/slack/index.vue?macro=true";
import { default as redirectKyw8p3kc74Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/slack/redirect.vue?macro=true";
import { default as _91eventId_93RbVe3i8p5sMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/webhook/[id]/events/[eventId].vue?macro=true";
import { default as indexvPOPTKEDr2Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/webhook/[id]/index.vue?macro=true";
import { default as index7cSYpdyNr0Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/webhook/index.vue?macro=true";
import { default as _91id_93nEc064YXbDMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/folders/[id].vue?macro=true";
import { default as indexqzRsKhmZjnMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/folders/index.vue?macro=true";
import { default as indexYXmhpSyxFpMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/grades/index.vue?macro=true";
import { default as indexeUMW5u1LyIMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/index.vue?macro=true";
import { default as index9LJRqc8DVhMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/invitationDomains/index.vue?macro=true";
import { default as index3Pm8ANXUVdMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/invitationSetting/index.vue?macro=true";
import { default as indexNKTuoIcVYOMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/ip/index.vue?macro=true";
import { default as indexYI9mtcDZrJMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/labels/index.vue?macro=true";
import { default as indexipFaal7QaAMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/[id]/index.vue?macro=true";
import { default as indexUY1N2BEXbqMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/[id]/items/[itemId]/index.vue?macro=true";
import { default as newVr2YrMn7kHMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/[id]/items/new.vue?macro=true";
import { default as workflows530RSLeac2Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue?macro=true";
import { default as indexdULxqWFPvTMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/index.vue?macro=true";
import { default as workflowsCj55wVdXUiMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/numberingGroups/[id]/workflows.vue?macro=true";
import { default as indexXJMRnYXbyGMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/numberingGroups/index.vue?macro=true";
import { default as indexDDtlMShkFWMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue?macro=true";
import { default as csvU08KGy0qyvMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue?macro=true";
import { default as indexmaI5zQG9jdMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/[org]/index.vue?macro=true";
import { default as _91id_935t1NVhNJknMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue?macro=true";
import { default as historyoyYRFRKnHrMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/history.vue?macro=true";
import { default as index6Nj9ZvySsqMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/index.vue?macro=true";
import { default as _91id_93OizrR9R1Q4Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/pipelines/[id].vue?macro=true";
import { default as indexlD5eWjo9ESMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/pipelines/index.vue?macro=true";
import { default as newt9zmLZYzDpMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/pipelines/new.vue?macro=true";
import { default as index7XyQaNQ8hRMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/proxyApplicants/index.vue?macro=true";
import { default as indexYjsXAxdw4LMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/proxyApprovers/index.vue?macro=true";
import { default as _91id_93pKXo6neV0iMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/roles/[id].vue?macro=true";
import { default as indexlNxdpgQc0eMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/roles/index.vue?macro=true";
import { default as edit57szCM8mwWMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/edit.vue?macro=true";
import { default as index0g7YgLcRxEMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/index.vue?macro=true";
import { default as indexfO4DVipCDPMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue?macro=true";
import { default as _91versionNumber_93pPTMXfIUU2Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue?macro=true";
import { default as indexq7aB54HR5TMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue?macro=true";
import { default as indexOWzigi8h6ZMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/index.vue?macro=true";
import { default as new5x1j8f8I0EMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/new.vue?macro=true";
import { default as indexKvgypWwnQkMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/stats/index.vue?macro=true";
import { default as index61iEjd0XpZMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/subStatuses/index.vue?macro=true";
import { default as assistantsKQxX2vYJe8Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/assistants.vue?macro=true";
import { default as backupsWtuY9L4rljMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/backups.vue?macro=true";
import { default as indexrMiDwGuX3bMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/index.vue?macro=true";
import { default as newsEqFVu4apyrMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/news.vue?macro=true";
import { default as notificationsJz3Uux2w03Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/notifications.vue?macro=true";
import { default as remind9KgWvZpVDQMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/remind.vue?macro=true";
import { default as settingsn4fCfpauJqMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/settings.vue?macro=true";
import { default as subdomain63pNzmvXqkMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/subdomain.vue?macro=true";
import { default as workingSr82q0syK7Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/working.vue?macro=true";
import { default as cleanXtsGHaTWYHMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/clean.vue?macro=true";
import { default as myticket78wciVS4JxMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue?macro=true";
import { default as taskUeWRNQtMCGMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/transfer/task.vue?macro=true";
import { default as deletebiktJql0STMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/viewer/delete.vue?macro=true";
import { default as indexDVLgzfRG95Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/viewer/index.vue?macro=true";
import { default as _91id_93OvGHVdrEyZMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/[id].vue?macro=true";
import { default as csvOlxM8qpGYOMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/csv.vue?macro=true";
import { default as indexSugBWFRjfeMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/index.vue?macro=true";
import { default as inviteBPWVCC6muhMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/invite.vue?macro=true";
import { default as unaffiliated0iw68IZ39CMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/unaffiliated.vue?macro=true";
import { default as editxoM96TVi2eMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue?macro=true";
import { default as indexLT63bsFsyWMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/[id]/index.vue?macro=true";
import { default as _91versionNumber_93VAB0IJUOu1Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue?macro=true";
import { default as indexwV94Ddnq9HMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue?macro=true";
import { default as index6nRujrQihfMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/index.vue?macro=true";
import { default as newgfTjit9vkyMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/new.vue?macro=true";
import { default as adminpFYXq6BGYJMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/admin.vue?macro=true";
import { default as signinRQCy8w8xImMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/assistants/[id]/signin.vue?macro=true";
import { default as callbackZN9mm3xDb1Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/auth/callback.vue?macro=true";
import { default as callback2F0h5qdXHkHMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/auth/callback2.vue?macro=true";
import { default as googleCallback2FiyLVCvb6wMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/auth/googleCallback2.vue?macro=true";
import { default as verify_email3VSJceJhwLMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/auth/verify_email.vue?macro=true";
import { default as indexbBbmoX4G5jMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/index.vue?macro=true";
import { default as indexexj3KaUq8vMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/mytickets/index.vue?macro=true";
import { default as newTs5Gs7zmArMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/mytickets/new.vue?macro=true";
import { default as indexiw9lOIB4NxMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/notifications/index.vue?macro=true";
import { default as indexAh1XCY0QMdMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/profile/index.vue?macro=true";
import { default as indexo6PEcGNkLtMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/security/index.vue?macro=true";
import { default as cloudsignKtkbHgdsoGMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue?macro=true";
import { default as googleChatpnIKB2vz2cMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/settings/googleChat.vue?macro=true";
import { default as notificationsDw3AJz3n7PMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/settings/notifications.vue?macro=true";
import { default as tokens1a9rpMTGx4Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/settings/tokens.vue?macro=true";
import { default as index0knwCbK5KnMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tasks/index.vue?macro=true";
import { default as editk78qnJtAzwMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue?macro=true";
import { default as editByApprovero3cZMcSMH4Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue?macro=true";
import { default as index7kytvGLkQGMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue?macro=true";
import { default as printbaZ6OTC5ulMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue?macro=true";
import { default as indexUvUdjPhuZ9Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/index.vue?macro=true";
import { default as indexiLdVftjbCwMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/view/index.vue?macro=true";
import { default as editcrBEfRZvAKMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue?macro=true";
import { default as indexNXJEntaaeMMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/view/settings/index.vue?macro=true";
import { default as newGprSnerJDEMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/view/settings/new.vue?macro=true";
import { default as searchJczdYeVwf7Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/workflows/search.vue?macro=true";
import { default as dashboardM4dwCUuizlMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard.vue?macro=true";
import { default as expiredZ6c8qHM0nuMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/expired.vue?macro=true";
import { default as indexp0DsLU1Pi9Meta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/files/[id]/index.vue?macro=true";
import { default as indexmmdB8VC3bwMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/index.vue?macro=true";
import { default as _91id_93lL1JAgxB0SMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/invitations/[id].vue?macro=true";
import { default as misocaFxUAtulVRaMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/misoca.vue?macro=true";
import { default as redirectR7oKa4RuwMMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/redirect.vue?macro=true";
import { default as selectsHI6doWnIsMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/select.vue?macro=true";
import { default as signinYlAzE6OrIcMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/signin.vue?macro=true";
import { default as startSso1hl2yh2UtkMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/startSso.vue?macro=true";
import { default as welcome1Ma2WjgfCCMeta } from "/tmp/build_a51b204e/frontend_nuxt3/pages/welcome.vue?macro=true";
export default [
  {
    name: adminpFYXq6BGYJMeta?.name,
    path: "/en/admin",
    meta: adminpFYXq6BGYJMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin.vue"),
    children: [
  {
    name: "admin-audit___en",
    path: "audit",
    meta: index2t9lpOtAVJMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/audit/index.vue")
  },
  {
    name: "admin-authentication___en",
    path: "authentication",
    meta: indexA66hVTIyoXMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/authentication/index.vue")
  },
  {
    name: "admin-automations-id-edit___en",
    path: "automations/:id()/edit",
    meta: editGAQB6MxBfqMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/automations/[id]/edit.vue")
  },
  {
    name: "admin-automations___en",
    path: "automations",
    meta: indexgaX51CHHU1Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/automations/index.vue")
  },
  {
    name: "admin-automations-new___en",
    path: "automations/new",
    meta: newLQC623ZDa9Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/automations/new.vue")
  },
  {
    name: "admin-billing___en",
    path: "billing",
    meta: indexR3oi0Au7wZMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/billing/index.vue")
  },
  {
    name: "admin-calculationVariables___en",
    path: "calculationVariables",
    meta: indexAzFUggvgqHMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/calculationVariables/index.vue")
  },
  {
    name: "admin-categories___en",
    path: "categories",
    meta: indexIJrT6wW8MYMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/categories/index.vue")
  },
  {
    name: "admin-connections-box-callback___en",
    path: "connections/box/callback",
    meta: callbackUP082BVtM6Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/box/callback.vue")
  },
  {
    name: "admin-connections-box___en",
    path: "connections/box",
    meta: indexZrTO2nuvnaMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/box/index.vue")
  },
  {
    name: "admin-connections-box-redirect___en",
    path: "connections/box/redirect",
    meta: redirectHykW2x1KHNMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/box/redirect.vue")
  },
  {
    name: "admin-connections-chatwork___en",
    path: "connections/chatwork",
    meta: indexbtUpeFVjybMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/chatwork/index.vue")
  },
  {
    name: "admin-connections-climberCloud___en",
    path: "connections/climberCloud",
    meta: indexXqbvwDwq1wMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue")
  },
  {
    name: "admin-connections-googleDrive___en",
    path: "connections/googleDrive",
    meta: indexa597tUrBXNMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory___en",
    path: "connections/gwsDirectory",
    meta: indexfXRD82HUSCMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory-results___en",
    path: "connections/gwsDirectory/results",
    meta: results0juFc56gIIMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue")
  },
  {
    name: "admin-connections-lineWorks___en",
    path: "connections/lineWorks",
    meta: indexRuPdACRVbiMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue")
  },
  {
    name: "admin-connections-microsoftTeams-callback___en",
    path: "connections/microsoftTeams/callback",
    meta: callbackrgBk3TTqWMMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue")
  },
  {
    name: "admin-connections-microsoftTeams___en",
    path: "connections/microsoftTeams",
    meta: indexJU49dQBEBzMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue")
  },
  {
    name: "admin-connections-serviceAccounts___en",
    path: "connections/serviceAccounts",
    meta: indexojFGSrBqPVMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue")
  },
  {
    name: "admin-connections-slack-callback___en",
    path: "connections/slack/callback",
    meta: callbackVJoLRbrvyKMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/slack/callback.vue")
  },
  {
    name: "admin-connections-slack___en",
    path: "connections/slack",
    meta: indexZsoAWmiErlMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/slack/index.vue")
  },
  {
    name: "admin-connections-slack-redirect___en",
    path: "connections/slack/redirect",
    meta: redirectKyw8p3kc74Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/slack/redirect.vue")
  },
  {
    name: "admin-connections-webhook-id-events-eventId___en",
    path: "connections/webhook/:id()/events/:eventId()",
    meta: _91eventId_93RbVe3i8p5sMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/webhook/[id]/events/[eventId].vue")
  },
  {
    name: "admin-connections-webhook-id___en",
    path: "connections/webhook/:id()",
    meta: indexvPOPTKEDr2Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/webhook/[id]/index.vue")
  },
  {
    name: "admin-connections-webhook___en",
    path: "connections/webhook",
    meta: index7cSYpdyNr0Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/webhook/index.vue")
  },
  {
    name: "admin-folders-id___en",
    path: "folders/:id()",
    meta: _91id_93nEc064YXbDMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/folders/[id].vue")
  },
  {
    name: "admin-folders___en",
    path: "folders",
    meta: indexqzRsKhmZjnMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/folders/index.vue")
  },
  {
    name: "admin-grades___en",
    path: "grades",
    meta: indexYXmhpSyxFpMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/grades/index.vue")
  },
  {
    name: "admin___en",
    path: "",
    meta: indexeUMW5u1LyIMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/index.vue")
  },
  {
    name: "admin-invitationDomains___en",
    path: "invitationDomains",
    meta: index9LJRqc8DVhMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/invitationDomains/index.vue")
  },
  {
    name: "admin-invitationSetting___en",
    path: "invitationSetting",
    meta: index3Pm8ANXUVdMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/invitationSetting/index.vue")
  },
  {
    name: "admin-ip___en",
    path: "ip",
    meta: indexNKTuoIcVYOMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/ip/index.vue")
  },
  {
    name: "admin-labels___en",
    path: "labels",
    meta: indexYI9mtcDZrJMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/labels/index.vue")
  },
  {
    name: "admin-masters-id___en",
    path: "masters/:id()",
    meta: indexipFaal7QaAMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/[id]/index.vue")
  },
  {
    name: "admin-masters-id-items-itemId___en",
    path: "masters/:id()/items/:itemId()",
    meta: indexUY1N2BEXbqMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/[id]/items/[itemId]/index.vue")
  },
  {
    name: "admin-masters-id-items-new___en",
    path: "masters/:id()/items/new",
    meta: newVr2YrMn7kHMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/[id]/items/new.vue")
  },
  {
    name: "admin-masters-id-workflows___en",
    path: "masters/:id()/workflows",
    meta: workflows530RSLeac2Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue")
  },
  {
    name: "admin-masters___en",
    path: "masters",
    meta: indexdULxqWFPvTMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/index.vue")
  },
  {
    name: "admin-numberingGroups-id-workflows___en",
    path: "numberingGroups/:id()/workflows",
    meta: workflowsCj55wVdXUiMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/numberingGroups/[id]/workflows.vue")
  },
  {
    name: "admin-numberingGroups___en",
    path: "numberingGroups",
    meta: indexXJMRnYXbyGMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/numberingGroups/index.vue")
  },
  {
    name: "admin-organizations-org-compare___en",
    path: "organizations/:org()/compare",
    meta: indexDDtlMShkFWMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue")
  },
  {
    name: "admin-organizations-org-csv___en",
    path: "organizations/:org()/csv",
    meta: csvU08KGy0qyvMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue")
  },
  {
    name: "admin-organizations-org___en",
    path: "organizations/:org()",
    meta: indexmaI5zQG9jdMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/[org]/index.vue")
  },
  {
    name: "admin-organizations-org-teams-id___en",
    path: "organizations/:org()/teams/:id()",
    meta: _91id_935t1NVhNJknMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue")
  },
  {
    name: "admin-organizations-history___en",
    path: "organizations/history",
    meta: historyoyYRFRKnHrMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/history.vue")
  },
  {
    name: "admin-organizations___en",
    path: "organizations",
    meta: index6Nj9ZvySsqMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/index.vue")
  },
  {
    name: "admin-pipelines-id___en",
    path: "pipelines/:id()",
    meta: _91id_93OizrR9R1Q4Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/pipelines/[id].vue")
  },
  {
    name: "admin-pipelines___en",
    path: "pipelines",
    meta: indexlD5eWjo9ESMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/pipelines/index.vue")
  },
  {
    name: "admin-pipelines-new___en",
    path: "pipelines/new",
    meta: newt9zmLZYzDpMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/pipelines/new.vue")
  },
  {
    name: "admin-proxyApplicants___en",
    path: "proxyApplicants",
    meta: index7XyQaNQ8hRMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/proxyApplicants/index.vue")
  },
  {
    name: "admin-proxyApprovers___en",
    path: "proxyApprovers",
    meta: indexYjsXAxdw4LMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/proxyApprovers/index.vue")
  },
  {
    name: "admin-roles-id___en",
    path: "roles/:id()",
    meta: _91id_93pKXo6neV0iMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/roles/[id].vue")
  },
  {
    name: "admin-roles___en",
    path: "roles",
    meta: indexlNxdpgQc0eMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/roles/index.vue")
  },
  {
    name: "admin-routes-id-edit___en",
    path: "routes/:id()/edit",
    meta: edit57szCM8mwWMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/edit.vue")
  },
  {
    name: "admin-routes-id___en",
    path: "routes/:id()",
    meta: index0g7YgLcRxEMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/index.vue")
  },
  {
    name: "admin-routes-id-simulator___en",
    path: "routes/:id()/simulator",
    meta: indexfO4DVipCDPMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue")
  },
  {
    name: "admin-routes-id-versions-versionNumber___en",
    path: "routes/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93pPTMXfIUU2Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-routes-id-versions___en",
    path: "routes/:id()/versions",
    meta: indexq7aB54HR5TMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue")
  },
  {
    name: "admin-routes___en",
    path: "routes",
    meta: indexOWzigi8h6ZMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/index.vue")
  },
  {
    name: "admin-routes-new___en",
    path: "routes/new",
    meta: new5x1j8f8I0EMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/new.vue")
  },
  {
    name: "admin-stats___en",
    path: "stats",
    meta: indexKvgypWwnQkMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/stats/index.vue")
  },
  {
    name: "admin-subStatuses___en",
    path: "subStatuses",
    meta: index61iEjd0XpZMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/subStatuses/index.vue")
  },
  {
    name: "admin-tenant-assistants___en",
    path: "tenant/assistants",
    meta: assistantsKQxX2vYJe8Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/assistants.vue")
  },
  {
    name: "admin-tenant-backups___en",
    path: "tenant/backups",
    meta: backupsWtuY9L4rljMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/backups.vue")
  },
  {
    name: "admin-tenant___en",
    path: "tenant",
    meta: indexrMiDwGuX3bMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/index.vue")
  },
  {
    name: "admin-tenant-news___en",
    path: "tenant/news",
    meta: newsEqFVu4apyrMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/news.vue")
  },
  {
    name: "admin-tenant-notifications___en",
    path: "tenant/notifications",
    meta: notificationsJz3Uux2w03Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/notifications.vue")
  },
  {
    name: "admin-tenant-remind___en",
    path: "tenant/remind",
    meta: remind9KgWvZpVDQMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/remind.vue")
  },
  {
    name: "admin-tenant-settings___en",
    path: "tenant/settings",
    meta: settingsn4fCfpauJqMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/settings.vue")
  },
  {
    name: "admin-tenant-subdomain___en",
    path: "tenant/subdomain",
    meta: subdomain63pNzmvXqkMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/subdomain.vue")
  },
  {
    name: "admin-tenant-working___en",
    path: "tenant/working",
    meta: workingSr82q0syK7Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/working.vue")
  },
  {
    name: "admin-tickets-clean___en",
    path: "tickets/clean",
    meta: cleanXtsGHaTWYHMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/clean.vue")
  },
  {
    name: "admin-tickets-transfer-myticket___en",
    path: "tickets/transfer/myticket",
    meta: myticket78wciVS4JxMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue")
  },
  {
    name: "admin-tickets-transfer-task___en",
    path: "tickets/transfer/task",
    meta: taskUeWRNQtMCGMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/transfer/task.vue")
  },
  {
    name: "admin-tickets-viewer-delete___en",
    path: "tickets/viewer/delete",
    meta: deletebiktJql0STMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/viewer/delete.vue")
  },
  {
    name: "admin-tickets-viewer___en",
    path: "tickets/viewer",
    meta: indexDVLgzfRG95Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/viewer/index.vue")
  },
  {
    name: "admin-users-id___en",
    path: "users/:id()",
    meta: _91id_93OvGHVdrEyZMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users-csv___en",
    path: "users/csv",
    meta: csvOlxM8qpGYOMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/csv.vue")
  },
  {
    name: "admin-users___en",
    path: "users",
    meta: indexSugBWFRjfeMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/index.vue")
  },
  {
    name: "admin-users-invite___en",
    path: "users/invite",
    meta: inviteBPWVCC6muhMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/invite.vue")
  },
  {
    name: "admin-users-unaffiliated___en",
    path: "users/unaffiliated",
    meta: unaffiliated0iw68IZ39CMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/unaffiliated.vue")
  },
  {
    name: "admin-workflows-id-edit___en",
    path: "workflows/:id()/edit",
    meta: editxoM96TVi2eMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue")
  },
  {
    name: "admin-workflows-id___en",
    path: "workflows/:id()",
    meta: indexLT63bsFsyWMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/[id]/index.vue")
  },
  {
    name: "admin-workflows-id-versions-versionNumber___en",
    path: "workflows/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93VAB0IJUOu1Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-workflows-id-versions___en",
    path: "workflows/:id()/versions",
    meta: indexwV94Ddnq9HMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue")
  },
  {
    name: "admin-workflows___en",
    path: "workflows",
    meta: index6nRujrQihfMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/index.vue")
  },
  {
    name: "admin-workflows-new___en",
    path: "workflows/new",
    meta: newgfTjit9vkyMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/new.vue")
  }
]
  },
  {
    name: adminpFYXq6BGYJMeta?.name,
    path: "/admin",
    meta: adminpFYXq6BGYJMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin.vue"),
    children: [
  {
    name: "admin-audit___ja",
    path: "audit",
    meta: index2t9lpOtAVJMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/audit/index.vue")
  },
  {
    name: "admin-authentication___ja",
    path: "authentication",
    meta: indexA66hVTIyoXMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/authentication/index.vue")
  },
  {
    name: "admin-automations-id-edit___ja",
    path: "automations/:id()/edit",
    meta: editGAQB6MxBfqMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/automations/[id]/edit.vue")
  },
  {
    name: "admin-automations___ja",
    path: "automations",
    meta: indexgaX51CHHU1Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/automations/index.vue")
  },
  {
    name: "admin-automations-new___ja",
    path: "automations/new",
    meta: newLQC623ZDa9Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/automations/new.vue")
  },
  {
    name: "admin-billing___ja",
    path: "billing",
    meta: indexR3oi0Au7wZMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/billing/index.vue")
  },
  {
    name: "admin-calculationVariables___ja",
    path: "calculationVariables",
    meta: indexAzFUggvgqHMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/calculationVariables/index.vue")
  },
  {
    name: "admin-categories___ja",
    path: "categories",
    meta: indexIJrT6wW8MYMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/categories/index.vue")
  },
  {
    name: "admin-connections-box-callback___ja",
    path: "connections/box/callback",
    meta: callbackUP082BVtM6Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/box/callback.vue")
  },
  {
    name: "admin-connections-box___ja",
    path: "connections/box",
    meta: indexZrTO2nuvnaMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/box/index.vue")
  },
  {
    name: "admin-connections-box-redirect___ja",
    path: "connections/box/redirect",
    meta: redirectHykW2x1KHNMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/box/redirect.vue")
  },
  {
    name: "admin-connections-chatwork___ja",
    path: "connections/chatwork",
    meta: indexbtUpeFVjybMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/chatwork/index.vue")
  },
  {
    name: "admin-connections-climberCloud___ja",
    path: "connections/climberCloud",
    meta: indexXqbvwDwq1wMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue")
  },
  {
    name: "admin-connections-googleDrive___ja",
    path: "connections/googleDrive",
    meta: indexa597tUrBXNMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory___ja",
    path: "connections/gwsDirectory",
    meta: indexfXRD82HUSCMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory-results___ja",
    path: "connections/gwsDirectory/results",
    meta: results0juFc56gIIMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue")
  },
  {
    name: "admin-connections-lineWorks___ja",
    path: "connections/lineWorks",
    meta: indexRuPdACRVbiMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue")
  },
  {
    name: "admin-connections-microsoftTeams-callback___ja",
    path: "connections/microsoftTeams/callback",
    meta: callbackrgBk3TTqWMMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue")
  },
  {
    name: "admin-connections-microsoftTeams___ja",
    path: "connections/microsoftTeams",
    meta: indexJU49dQBEBzMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue")
  },
  {
    name: "admin-connections-serviceAccounts___ja",
    path: "connections/serviceAccounts",
    meta: indexojFGSrBqPVMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue")
  },
  {
    name: "admin-connections-slack-callback___ja",
    path: "connections/slack/callback",
    meta: callbackVJoLRbrvyKMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/slack/callback.vue")
  },
  {
    name: "admin-connections-slack___ja",
    path: "connections/slack",
    meta: indexZsoAWmiErlMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/slack/index.vue")
  },
  {
    name: "admin-connections-slack-redirect___ja",
    path: "connections/slack/redirect",
    meta: redirectKyw8p3kc74Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/slack/redirect.vue")
  },
  {
    name: "admin-connections-webhook-id-events-eventId___ja",
    path: "connections/webhook/:id()/events/:eventId()",
    meta: _91eventId_93RbVe3i8p5sMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/webhook/[id]/events/[eventId].vue")
  },
  {
    name: "admin-connections-webhook-id___ja",
    path: "connections/webhook/:id()",
    meta: indexvPOPTKEDr2Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/webhook/[id]/index.vue")
  },
  {
    name: "admin-connections-webhook___ja",
    path: "connections/webhook",
    meta: index7cSYpdyNr0Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/connections/webhook/index.vue")
  },
  {
    name: "admin-folders-id___ja",
    path: "folders/:id()",
    meta: _91id_93nEc064YXbDMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/folders/[id].vue")
  },
  {
    name: "admin-folders___ja",
    path: "folders",
    meta: indexqzRsKhmZjnMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/folders/index.vue")
  },
  {
    name: "admin-grades___ja",
    path: "grades",
    meta: indexYXmhpSyxFpMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/grades/index.vue")
  },
  {
    name: "admin___ja",
    path: "",
    meta: indexeUMW5u1LyIMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/index.vue")
  },
  {
    name: "admin-invitationDomains___ja",
    path: "invitationDomains",
    meta: index9LJRqc8DVhMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/invitationDomains/index.vue")
  },
  {
    name: "admin-invitationSetting___ja",
    path: "invitationSetting",
    meta: index3Pm8ANXUVdMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/invitationSetting/index.vue")
  },
  {
    name: "admin-ip___ja",
    path: "ip",
    meta: indexNKTuoIcVYOMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/ip/index.vue")
  },
  {
    name: "admin-labels___ja",
    path: "labels",
    meta: indexYI9mtcDZrJMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/labels/index.vue")
  },
  {
    name: "admin-masters-id___ja",
    path: "masters/:id()",
    meta: indexipFaal7QaAMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/[id]/index.vue")
  },
  {
    name: "admin-masters-id-items-itemId___ja",
    path: "masters/:id()/items/:itemId()",
    meta: indexUY1N2BEXbqMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/[id]/items/[itemId]/index.vue")
  },
  {
    name: "admin-masters-id-items-new___ja",
    path: "masters/:id()/items/new",
    meta: newVr2YrMn7kHMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/[id]/items/new.vue")
  },
  {
    name: "admin-masters-id-workflows___ja",
    path: "masters/:id()/workflows",
    meta: workflows530RSLeac2Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue")
  },
  {
    name: "admin-masters___ja",
    path: "masters",
    meta: indexdULxqWFPvTMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/masters/index.vue")
  },
  {
    name: "admin-numberingGroups-id-workflows___ja",
    path: "numberingGroups/:id()/workflows",
    meta: workflowsCj55wVdXUiMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/numberingGroups/[id]/workflows.vue")
  },
  {
    name: "admin-numberingGroups___ja",
    path: "numberingGroups",
    meta: indexXJMRnYXbyGMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/numberingGroups/index.vue")
  },
  {
    name: "admin-organizations-org-compare___ja",
    path: "organizations/:org()/compare",
    meta: indexDDtlMShkFWMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue")
  },
  {
    name: "admin-organizations-org-csv___ja",
    path: "organizations/:org()/csv",
    meta: csvU08KGy0qyvMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue")
  },
  {
    name: "admin-organizations-org___ja",
    path: "organizations/:org()",
    meta: indexmaI5zQG9jdMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/[org]/index.vue")
  },
  {
    name: "admin-organizations-org-teams-id___ja",
    path: "organizations/:org()/teams/:id()",
    meta: _91id_935t1NVhNJknMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue")
  },
  {
    name: "admin-organizations-history___ja",
    path: "organizations/history",
    meta: historyoyYRFRKnHrMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/history.vue")
  },
  {
    name: "admin-organizations___ja",
    path: "organizations",
    meta: index6Nj9ZvySsqMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/organizations/index.vue")
  },
  {
    name: "admin-pipelines-id___ja",
    path: "pipelines/:id()",
    meta: _91id_93OizrR9R1Q4Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/pipelines/[id].vue")
  },
  {
    name: "admin-pipelines___ja",
    path: "pipelines",
    meta: indexlD5eWjo9ESMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/pipelines/index.vue")
  },
  {
    name: "admin-pipelines-new___ja",
    path: "pipelines/new",
    meta: newt9zmLZYzDpMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/pipelines/new.vue")
  },
  {
    name: "admin-proxyApplicants___ja",
    path: "proxyApplicants",
    meta: index7XyQaNQ8hRMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/proxyApplicants/index.vue")
  },
  {
    name: "admin-proxyApprovers___ja",
    path: "proxyApprovers",
    meta: indexYjsXAxdw4LMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/proxyApprovers/index.vue")
  },
  {
    name: "admin-roles-id___ja",
    path: "roles/:id()",
    meta: _91id_93pKXo6neV0iMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/roles/[id].vue")
  },
  {
    name: "admin-roles___ja",
    path: "roles",
    meta: indexlNxdpgQc0eMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/roles/index.vue")
  },
  {
    name: "admin-routes-id-edit___ja",
    path: "routes/:id()/edit",
    meta: edit57szCM8mwWMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/edit.vue")
  },
  {
    name: "admin-routes-id___ja",
    path: "routes/:id()",
    meta: index0g7YgLcRxEMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/index.vue")
  },
  {
    name: "admin-routes-id-simulator___ja",
    path: "routes/:id()/simulator",
    meta: indexfO4DVipCDPMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue")
  },
  {
    name: "admin-routes-id-versions-versionNumber___ja",
    path: "routes/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93pPTMXfIUU2Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-routes-id-versions___ja",
    path: "routes/:id()/versions",
    meta: indexq7aB54HR5TMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue")
  },
  {
    name: "admin-routes___ja",
    path: "routes",
    meta: indexOWzigi8h6ZMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/index.vue")
  },
  {
    name: "admin-routes-new___ja",
    path: "routes/new",
    meta: new5x1j8f8I0EMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/routes/new.vue")
  },
  {
    name: "admin-stats___ja",
    path: "stats",
    meta: indexKvgypWwnQkMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/stats/index.vue")
  },
  {
    name: "admin-subStatuses___ja",
    path: "subStatuses",
    meta: index61iEjd0XpZMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/subStatuses/index.vue")
  },
  {
    name: "admin-tenant-assistants___ja",
    path: "tenant/assistants",
    meta: assistantsKQxX2vYJe8Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/assistants.vue")
  },
  {
    name: "admin-tenant-backups___ja",
    path: "tenant/backups",
    meta: backupsWtuY9L4rljMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/backups.vue")
  },
  {
    name: "admin-tenant___ja",
    path: "tenant",
    meta: indexrMiDwGuX3bMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/index.vue")
  },
  {
    name: "admin-tenant-news___ja",
    path: "tenant/news",
    meta: newsEqFVu4apyrMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/news.vue")
  },
  {
    name: "admin-tenant-notifications___ja",
    path: "tenant/notifications",
    meta: notificationsJz3Uux2w03Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/notifications.vue")
  },
  {
    name: "admin-tenant-remind___ja",
    path: "tenant/remind",
    meta: remind9KgWvZpVDQMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/remind.vue")
  },
  {
    name: "admin-tenant-settings___ja",
    path: "tenant/settings",
    meta: settingsn4fCfpauJqMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/settings.vue")
  },
  {
    name: "admin-tenant-subdomain___ja",
    path: "tenant/subdomain",
    meta: subdomain63pNzmvXqkMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/subdomain.vue")
  },
  {
    name: "admin-tenant-working___ja",
    path: "tenant/working",
    meta: workingSr82q0syK7Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tenant/working.vue")
  },
  {
    name: "admin-tickets-clean___ja",
    path: "tickets/clean",
    meta: cleanXtsGHaTWYHMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/clean.vue")
  },
  {
    name: "admin-tickets-transfer-myticket___ja",
    path: "tickets/transfer/myticket",
    meta: myticket78wciVS4JxMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue")
  },
  {
    name: "admin-tickets-transfer-task___ja",
    path: "tickets/transfer/task",
    meta: taskUeWRNQtMCGMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/transfer/task.vue")
  },
  {
    name: "admin-tickets-viewer-delete___ja",
    path: "tickets/viewer/delete",
    meta: deletebiktJql0STMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/viewer/delete.vue")
  },
  {
    name: "admin-tickets-viewer___ja",
    path: "tickets/viewer",
    meta: indexDVLgzfRG95Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/tickets/viewer/index.vue")
  },
  {
    name: "admin-users-id___ja",
    path: "users/:id()",
    meta: _91id_93OvGHVdrEyZMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users-csv___ja",
    path: "users/csv",
    meta: csvOlxM8qpGYOMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/csv.vue")
  },
  {
    name: "admin-users___ja",
    path: "users",
    meta: indexSugBWFRjfeMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/index.vue")
  },
  {
    name: "admin-users-invite___ja",
    path: "users/invite",
    meta: inviteBPWVCC6muhMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/invite.vue")
  },
  {
    name: "admin-users-unaffiliated___ja",
    path: "users/unaffiliated",
    meta: unaffiliated0iw68IZ39CMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/users/unaffiliated.vue")
  },
  {
    name: "admin-workflows-id-edit___ja",
    path: "workflows/:id()/edit",
    meta: editxoM96TVi2eMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue")
  },
  {
    name: "admin-workflows-id___ja",
    path: "workflows/:id()",
    meta: indexLT63bsFsyWMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/[id]/index.vue")
  },
  {
    name: "admin-workflows-id-versions-versionNumber___ja",
    path: "workflows/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93VAB0IJUOu1Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-workflows-id-versions___ja",
    path: "workflows/:id()/versions",
    meta: indexwV94Ddnq9HMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue")
  },
  {
    name: "admin-workflows___ja",
    path: "workflows",
    meta: index6nRujrQihfMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/index.vue")
  },
  {
    name: "admin-workflows-new___ja",
    path: "workflows/new",
    meta: newgfTjit9vkyMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/admin/workflows/new.vue")
  }
]
  },
  {
    name: "assistants-id-signin___en",
    path: "/en/assistants/:id()/signin",
    meta: signinRQCy8w8xImMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/assistants/[id]/signin.vue")
  },
  {
    name: "assistants-id-signin___ja",
    path: "/assistants/:id()/signin",
    meta: signinRQCy8w8xImMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/assistants/[id]/signin.vue")
  },
  {
    name: "auth-callback___en",
    path: "/en/auth/callback",
    meta: callbackZN9mm3xDb1Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/auth/callback.vue")
  },
  {
    name: "auth-callback___ja",
    path: "/auth/callback",
    meta: callbackZN9mm3xDb1Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/auth/callback.vue")
  },
  {
    name: "auth-callback2___en",
    path: "/en/auth/callback2",
    meta: callback2F0h5qdXHkHMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/auth/callback2.vue")
  },
  {
    name: "auth-callback2___ja",
    path: "/auth/callback2",
    meta: callback2F0h5qdXHkHMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/auth/callback2.vue")
  },
  {
    name: "auth-googleCallback2___en",
    path: "/en/auth/googleCallback2",
    meta: googleCallback2FiyLVCvb6wMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/auth/googleCallback2.vue")
  },
  {
    name: "auth-googleCallback2___ja",
    path: "/auth/googleCallback2",
    meta: googleCallback2FiyLVCvb6wMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/auth/googleCallback2.vue")
  },
  {
    name: "auth-verify_email___en",
    path: "/en/auth/verify_email",
    meta: verify_email3VSJceJhwLMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/auth/verify_email.vue")
  },
  {
    name: "auth-verify_email___ja",
    path: "/auth/verify_email",
    meta: verify_email3VSJceJhwLMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/auth/verify_email.vue")
  },
  {
    name: dashboardM4dwCUuizlMeta?.name,
    path: "/en/dashboard",
    meta: dashboardM4dwCUuizlMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard___en",
    path: "",
    meta: indexbBbmoX4G5jMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-mytickets___en",
    path: "mytickets",
    meta: indexexj3KaUq8vMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/mytickets/index.vue")
  },
  {
    name: "dashboard-mytickets-new___en",
    path: "mytickets/new",
    meta: newTs5Gs7zmArMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/mytickets/new.vue")
  },
  {
    name: "dashboard-notifications___en",
    path: "notifications",
    meta: indexiw9lOIB4NxMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/notifications/index.vue")
  },
  {
    name: "dashboard-profile___en",
    path: "profile",
    meta: indexAh1XCY0QMdMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-security___en",
    path: "security",
    meta: indexo6PEcGNkLtMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/security/index.vue")
  },
  {
    name: "dashboard-settings-cloudsign___en",
    path: "settings/cloudsign",
    meta: cloudsignKtkbHgdsoGMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue")
  },
  {
    name: "dashboard-settings-googleChat___en",
    path: "settings/googleChat",
    meta: googleChatpnIKB2vz2cMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/settings/googleChat.vue")
  },
  {
    name: "dashboard-settings-notifications___en",
    path: "settings/notifications",
    meta: notificationsDw3AJz3n7PMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/settings/notifications.vue")
  },
  {
    name: "dashboard-settings-tokens___en",
    path: "settings/tokens",
    meta: tokens1a9rpMTGx4Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/settings/tokens.vue")
  },
  {
    name: "dashboard-tasks___en",
    path: "tasks",
    meta: index0knwCbK5KnMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tasks/index.vue")
  },
  {
    name: "dashboard-tickets-id-edit___en",
    path: "tickets/:id()/edit",
    meta: editk78qnJtAzwMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue")
  },
  {
    name: "dashboard-tickets-id-editByApprover___en",
    path: "tickets/:id()/editByApprover",
    meta: editByApprovero3cZMcSMH4Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue")
  },
  {
    name: "dashboard-tickets-id___en",
    path: "tickets/:id()",
    meta: index7kytvGLkQGMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue")
  },
  {
    name: "dashboard-tickets-id-print___en",
    path: "tickets/:id()/print",
    meta: printbaZ6OTC5ulMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue")
  },
  {
    name: "dashboard-tickets___en",
    path: "tickets",
    meta: indexUvUdjPhuZ9Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/index.vue")
  },
  {
    name: "dashboard-view___en",
    path: "view",
    meta: indexiLdVftjbCwMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/view/index.vue")
  },
  {
    name: "dashboard-view-settings-id-edit___en",
    path: "view/settings/:id()/edit",
    meta: editcrBEfRZvAKMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue")
  },
  {
    name: "dashboard-view-settings___en",
    path: "view/settings",
    meta: indexNXJEntaaeMMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/view/settings/index.vue")
  },
  {
    name: "dashboard-view-settings-new___en",
    path: "view/settings/new",
    meta: newGprSnerJDEMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/view/settings/new.vue")
  },
  {
    name: "dashboard-workflows-search___en",
    path: "workflows/search",
    meta: searchJczdYeVwf7Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/workflows/search.vue")
  }
]
  },
  {
    name: dashboardM4dwCUuizlMeta?.name,
    path: "/dashboard",
    meta: dashboardM4dwCUuizlMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard___ja",
    path: "",
    meta: indexbBbmoX4G5jMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-mytickets___ja",
    path: "mytickets",
    meta: indexexj3KaUq8vMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/mytickets/index.vue")
  },
  {
    name: "dashboard-mytickets-new___ja",
    path: "mytickets/new",
    meta: newTs5Gs7zmArMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/mytickets/new.vue")
  },
  {
    name: "dashboard-notifications___ja",
    path: "notifications",
    meta: indexiw9lOIB4NxMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/notifications/index.vue")
  },
  {
    name: "dashboard-profile___ja",
    path: "profile",
    meta: indexAh1XCY0QMdMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-security___ja",
    path: "security",
    meta: indexo6PEcGNkLtMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/security/index.vue")
  },
  {
    name: "dashboard-settings-cloudsign___ja",
    path: "settings/cloudsign",
    meta: cloudsignKtkbHgdsoGMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue")
  },
  {
    name: "dashboard-settings-googleChat___ja",
    path: "settings/googleChat",
    meta: googleChatpnIKB2vz2cMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/settings/googleChat.vue")
  },
  {
    name: "dashboard-settings-notifications___ja",
    path: "settings/notifications",
    meta: notificationsDw3AJz3n7PMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/settings/notifications.vue")
  },
  {
    name: "dashboard-settings-tokens___ja",
    path: "settings/tokens",
    meta: tokens1a9rpMTGx4Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/settings/tokens.vue")
  },
  {
    name: "dashboard-tasks___ja",
    path: "tasks",
    meta: index0knwCbK5KnMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tasks/index.vue")
  },
  {
    name: "dashboard-tickets-id-edit___ja",
    path: "tickets/:id()/edit",
    meta: editk78qnJtAzwMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue")
  },
  {
    name: "dashboard-tickets-id-editByApprover___ja",
    path: "tickets/:id()/editByApprover",
    meta: editByApprovero3cZMcSMH4Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue")
  },
  {
    name: "dashboard-tickets-id___ja",
    path: "tickets/:id()",
    meta: index7kytvGLkQGMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue")
  },
  {
    name: "dashboard-tickets-id-print___ja",
    path: "tickets/:id()/print",
    meta: printbaZ6OTC5ulMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue")
  },
  {
    name: "dashboard-tickets___ja",
    path: "tickets",
    meta: indexUvUdjPhuZ9Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/tickets/index.vue")
  },
  {
    name: "dashboard-view___ja",
    path: "view",
    meta: indexiLdVftjbCwMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/view/index.vue")
  },
  {
    name: "dashboard-view-settings-id-edit___ja",
    path: "view/settings/:id()/edit",
    meta: editcrBEfRZvAKMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue")
  },
  {
    name: "dashboard-view-settings___ja",
    path: "view/settings",
    meta: indexNXJEntaaeMMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/view/settings/index.vue")
  },
  {
    name: "dashboard-view-settings-new___ja",
    path: "view/settings/new",
    meta: newGprSnerJDEMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/view/settings/new.vue")
  },
  {
    name: "dashboard-workflows-search___ja",
    path: "workflows/search",
    meta: searchJczdYeVwf7Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/dashboard/workflows/search.vue")
  }
]
  },
  {
    name: "expired___en",
    path: "/en/expired",
    meta: expiredZ6c8qHM0nuMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/expired.vue")
  },
  {
    name: "expired___ja",
    path: "/expired",
    meta: expiredZ6c8qHM0nuMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/expired.vue")
  },
  {
    name: "files-id___en",
    path: "/en/files/:id()",
    meta: indexp0DsLU1Pi9Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/files/[id]/index.vue")
  },
  {
    name: "files-id___ja",
    path: "/files/:id()",
    meta: indexp0DsLU1Pi9Meta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/files/[id]/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexmmdB8VC3bwMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/index.vue")
  },
  {
    name: "index___ja",
    path: "/",
    meta: indexmmdB8VC3bwMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/index.vue")
  },
  {
    name: "invitations-id___en",
    path: "/en/invitations/:id()",
    meta: _91id_93lL1JAgxB0SMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/invitations/[id].vue")
  },
  {
    name: "invitations-id___ja",
    path: "/invitations/:id()",
    meta: _91id_93lL1JAgxB0SMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/invitations/[id].vue")
  },
  {
    name: "misoca___en",
    path: "/en/misoca",
    meta: misocaFxUAtulVRaMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/misoca.vue")
  },
  {
    name: "misoca___ja",
    path: "/misoca",
    meta: misocaFxUAtulVRaMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/misoca.vue")
  },
  {
    name: "redirect___en",
    path: "/en/redirect",
    meta: redirectR7oKa4RuwMMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/redirect.vue")
  },
  {
    name: "redirect___ja",
    path: "/redirect",
    meta: redirectR7oKa4RuwMMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/redirect.vue")
  },
  {
    name: "select___en",
    path: "/en/select",
    meta: selectsHI6doWnIsMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/select.vue")
  },
  {
    name: "select___ja",
    path: "/select",
    meta: selectsHI6doWnIsMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/select.vue")
  },
  {
    name: "signin___en",
    path: "/en/signin",
    meta: signinYlAzE6OrIcMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/signin.vue")
  },
  {
    name: "signin___ja",
    path: "/signin",
    meta: signinYlAzE6OrIcMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/signin.vue")
  },
  {
    name: "startSso___en",
    path: "/en/startSso",
    meta: startSso1hl2yh2UtkMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/startSso.vue")
  },
  {
    name: "startSso___ja",
    path: "/startSso",
    meta: startSso1hl2yh2UtkMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/startSso.vue")
  },
  {
    name: "welcome___en",
    path: "/en/welcome",
    meta: welcome1Ma2WjgfCCMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/welcome.vue")
  },
  {
    name: "welcome___ja",
    path: "/welcome",
    meta: welcome1Ma2WjgfCCMeta || {},
    component: () => import("/tmp/build_a51b204e/frontend_nuxt3/pages/welcome.vue")
  }
]
<template>
  <VBtn
    :block="block"
    :class="classList"
    :color="color"
    :disabled="disabled"
    :elevation="elevation"
    :href="href"
    :icon="icon"
    :loading="loading"
    :prepend-icon="prependIcon"
    :rel="rel"
    :size="size"
    :target="target"
    :tile="tile"
    :to="to"
    :variant="variant"
    @click="emit('click', $event)"
  >
    <template v-if="defaultSlotDefined" #default>
      <slot />
    </template>
  </VBtn>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    variant?: 'elevated' | 'outlined' | 'text' | 'flat' // kickflowで使用可能なのもののみ列挙
    color?: string
    disabled?: boolean
    block?: boolean
    loading?: boolean
    size?: 'x-small' | 'small' | 'default' | 'large' | 'x-large'
    to?: string
    href?: string
    target?: string | null
    icon?: boolean | string
    prependIcon?: string
    wrapText?: boolean // trueの場合、ボタンの中のテキストを折り返す
    transform?: 'uppercase' | 'none' // テキストを大文字化するか。マテリアルデザインはデフォルトでuppercase。
    elevation?: string | number
    tile?: boolean
  }>(),
  {
    variant: undefined, // 通常はelevated、ダイアログではtext
    color: undefined,
    disabled: false,
    block: false,
    loading: false,
    size: 'default',
    to: undefined,
    href: undefined,
    target: undefined,
    icon: false,
    prependIcon: undefined,
    wrapText: false,
    transform: 'uppercase',
    elevation: undefined,
    tile: false,
  }
)

const emit = defineEmits<{
  click: [e: MouseEvent]
}>()

const slots = defineSlots<{
  default: any
}>()

const classList = computed(() => {
  const list = ['k-btn']
  if (props.wrapText) {
    list.push('k-btn--wrap-text')
  }
  if (props.transform === 'none') {
    list.push('k-btn--transform-none')
  }
  return list
})

const rel = computed(() => {
  if (props.target === '_blank') {
    return 'noopener noreferrer'
  }
  return undefined
})

const defaultSlotDefined = slots.default !== undefined
</script>

<style scoped lang="scss">
// ボタンの中のテキストを折り返す
.k-btn--wrap-text :deep(.v-btn__content) {
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
}

.k-btn--transform-none {
  text-transform: none;
}
</style>

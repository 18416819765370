<template>
  <VSnackbar
    :color="color"
    :location="location"
    :model-value="modelValue"
    :timeout="timeout"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <slot />

    <template v-if="hasActionsSlot" #actions>
      <slot name="actions" />
    </template>
  </VSnackbar>
</template>

<script setup lang="ts">
import type { Anchor } from '~/types/pages/components'

withDefaults(
  defineProps<{
    modelValue: boolean
    color?: string
    location?: Anchor
    timeout?: number
  }>(),
  {
    color: 'success',
    location: 'top',
    timeout: -1,
  }
)

const emit = defineEmits<{
  'update:modelValue': [value: boolean]
}>()

const slots = defineSlots<{
  default: any
  actions: any
}>()

const hasActionsSlot = computed(() => !!slots.actions)
</script>

<style scoped></style>

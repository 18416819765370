<template>
  <VListItem
    :active="active"
    :base-color="baseColor"
    :color="color"
    :density="density"
    :exact="exact"
    :prepend-icon="prependIcon"
    :subtitle="subtitle"
    :title="title"
    :to="to"
    :value="value"
  >
    <template v-if="titleSlotDefined" #title="slotProps">
      <slot name="title" v-bind="slotProps" />
    </template>

    <template v-if="appendSlotDefined" #append="slotProps">
      <slot name="append" v-bind="slotProps" />
    </template>

    <template v-if="prependSlotDefined" #prepend="slotProps">
      <slot name="prepend" v-bind="slotProps" />
    </template>
    <template v-else-if="prependCheckbox" #prepend="{ isActive }">
      <VListItemAction>
        <VCheckboxBtn :model-value="isActive"></VCheckboxBtn>
      </VListItemAction>
    </template>
  </VListItem>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    active?: boolean
    baseColor?: string
    color?: string
    density?: 'default' | 'compact'
    title?: string
    subtitle?: string
    prependIcon?: string
    to?: string
    value?: any
    exact?: boolean
    prependCheckbox?: boolean // prependにチェックボックスを表示するか
  }>(),
  {
    // eslint-disable-next-line vue/no-boolean-default
    active: undefined,
    baseColor: undefined,
    color: undefined,
    density: 'default',
    title: undefined,
    subtitle: undefined,
    prependIcon: undefined,
    to: undefined,
    value: undefined,
    exact: false,
    prependCheckbox: false,
  }
)

const slots = defineSlots<{
  title?: string
  append: any
  prepend: any
}>()

const titleSlotDefined = computed(() => {
  return slots.title !== undefined
})

const appendSlotDefined = computed(() => {
  return slots.append !== undefined
})

const prependSlotDefined = computed(() => {
  return slots.prepend !== undefined
})
</script>

<style scoped></style>


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexQoAOfSaRtTMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/audit/index.vue?macro=true";
import { default as indexZIxzDHjGQcMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/authentication/index.vue?macro=true";
import { default as editudpZYr15uJMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/automations/[id]/edit.vue?macro=true";
import { default as indexRjQ3RO9t6NMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/automations/index.vue?macro=true";
import { default as newjlJEuKzO25Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/automations/new.vue?macro=true";
import { default as indexEEPA7tvgfyMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/billing/index.vue?macro=true";
import { default as indexAr9GkS4E5qMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/calculationVariables/index.vue?macro=true";
import { default as index9QHlQ8YezHMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/categories/index.vue?macro=true";
import { default as callbackeT1ByvkYWYMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/box/callback.vue?macro=true";
import { default as indexCGv6xXnoUKMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/box/index.vue?macro=true";
import { default as redirect6v3qwGDCE4Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/box/redirect.vue?macro=true";
import { default as indexkSAN9hAQroMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/chatwork/index.vue?macro=true";
import { default as indexxCF4cTb7DYMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue?macro=true";
import { default as indextVEQeGr9xzMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue?macro=true";
import { default as index6wz8rU9REvMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue?macro=true";
import { default as resultskMnZCipeGqMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue?macro=true";
import { default as indexNQIKe59uHgMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue?macro=true";
import { default as callback3KxQyZO3v7Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue?macro=true";
import { default as indexYMOrvV22CMMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue?macro=true";
import { default as indexGHhfTFgWc2Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue?macro=true";
import { default as callbackgRiXVODSaRMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/slack/callback.vue?macro=true";
import { default as indexHnVfEZMujHMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/slack/index.vue?macro=true";
import { default as redirectYcfv1lXfoxMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/slack/redirect.vue?macro=true";
import { default as _91eventId_93UJsDX9ooqoMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/webhook/[id]/events/[eventId].vue?macro=true";
import { default as indexYW8aZjuuPHMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/webhook/[id]/index.vue?macro=true";
import { default as indexApqngh1qDNMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/webhook/index.vue?macro=true";
import { default as _91id_930vLzwoVteFMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/folders/[id].vue?macro=true";
import { default as indextbAELrepJKMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/folders/index.vue?macro=true";
import { default as indexL8IzfM05ZdMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/grades/index.vue?macro=true";
import { default as indexG8edjbHcybMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/index.vue?macro=true";
import { default as indexpfJgbd2RlvMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/invitationDomains/index.vue?macro=true";
import { default as index5xNEg04ACpMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/invitationSetting/index.vue?macro=true";
import { default as indexHkDFmn3teOMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/ip/index.vue?macro=true";
import { default as indexnxLvC8vZMkMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/labels/index.vue?macro=true";
import { default as indexNNJHwxetAmMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/[id]/index.vue?macro=true";
import { default as indexQxIyJ3bqg0Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/[id]/items/[itemId]/index.vue?macro=true";
import { default as newLtGODvlM6uMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/[id]/items/new.vue?macro=true";
import { default as workflowsBrQW93neQXMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue?macro=true";
import { default as indexOyN17VtSkaMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/index.vue?macro=true";
import { default as workflowsTUNLu2CLIzMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/numberingGroups/[id]/workflows.vue?macro=true";
import { default as indexJT2NWyCVRAMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/numberingGroups/index.vue?macro=true";
import { default as indexA1qxQwSnUaMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue?macro=true";
import { default as csvSaTRj4bVlwMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue?macro=true";
import { default as indexryLEX5KDUMMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/[org]/index.vue?macro=true";
import { default as _91id_93aDWGVCbOnbMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue?macro=true";
import { default as historyCth25lDZavMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/history.vue?macro=true";
import { default as indexDSQGZ2OvZYMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/index.vue?macro=true";
import { default as _91id_93fCE02gthIFMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/pipelines/[id].vue?macro=true";
import { default as indexmhInExbow7Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/pipelines/index.vue?macro=true";
import { default as newE5lRNAqdkWMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/pipelines/new.vue?macro=true";
import { default as indexTu6E37hYJ5Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/proxyApplicants/index.vue?macro=true";
import { default as indexswn1nqTT6xMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/proxyApprovers/index.vue?macro=true";
import { default as _91id_93A29Ksh3ThPMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/roles/[id].vue?macro=true";
import { default as indexHNl4CuaEqwMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/roles/index.vue?macro=true";
import { default as editb4oX6ktZIxMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/edit.vue?macro=true";
import { default as indexeBV0FoEZA9Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/index.vue?macro=true";
import { default as indexGfTWhDwbHwMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue?macro=true";
import { default as _91versionNumber_93CmG2VoyNIsMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue?macro=true";
import { default as indexUhHHKYk0cTMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue?macro=true";
import { default as indexejT6rTsjzgMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/index.vue?macro=true";
import { default as newT8Yo1xqAryMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/new.vue?macro=true";
import { default as indexTwPmSZahDlMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/stats/index.vue?macro=true";
import { default as indexsQBZnLZBSIMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/subStatuses/index.vue?macro=true";
import { default as assistantsIshp9DFXvWMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/assistants.vue?macro=true";
import { default as backupsfIC3ohmnJFMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/backups.vue?macro=true";
import { default as indexvaZUitJNzaMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/index.vue?macro=true";
import { default as newsXoSy8Hh5MrMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/news.vue?macro=true";
import { default as notificationsc2RNmZclGrMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/notifications.vue?macro=true";
import { default as remindO54VDrc4OgMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/remind.vue?macro=true";
import { default as settingsDJd9OLsqaSMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/settings.vue?macro=true";
import { default as subdomainAFdz7rtkhwMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/subdomain.vue?macro=true";
import { default as workingOhbdZw8HQyMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/working.vue?macro=true";
import { default as cleantPfU2FQWPXMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/clean.vue?macro=true";
import { default as myticketdlVjSfNOkZMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue?macro=true";
import { default as taskZWecsoBiMjMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/transfer/task.vue?macro=true";
import { default as deleteYqFzIgDL0OMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/viewer/delete.vue?macro=true";
import { default as indexpDLdoMVHOZMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/viewer/index.vue?macro=true";
import { default as _91id_93zBo6ft3Sl7Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/[id].vue?macro=true";
import { default as csv9j10bvuCWbMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/csv.vue?macro=true";
import { default as indexAWhn9ZYTKlMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/index.vue?macro=true";
import { default as inviteqNPLV7ZU01Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/invite.vue?macro=true";
import { default as unaffiliatedojbM0d1w3fMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/unaffiliated.vue?macro=true";
import { default as edit5KH0Pcaie1Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue?macro=true";
import { default as indexLXpLwCP2uMMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/[id]/index.vue?macro=true";
import { default as _91versionNumber_93Izqy9ejTqOMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue?macro=true";
import { default as indexz75LwZYRePMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue?macro=true";
import { default as indexIzjgq0sN16Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/index.vue?macro=true";
import { default as newPhTRj653HWMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/new.vue?macro=true";
import { default as adminvXwjZ09MTYMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin.vue?macro=true";
import { default as signinecNiUfAXJUMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/assistants/[id]/signin.vue?macro=true";
import { default as callback4LBHbfgW5CMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/auth/callback.vue?macro=true";
import { default as callback22TTCtJI1BJMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/auth/callback2.vue?macro=true";
import { default as googleCallback2OA0uvHGPFgMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/auth/googleCallback2.vue?macro=true";
import { default as verify_email2h48JkkMQMMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/auth/verify_email.vue?macro=true";
import { default as indexaenk5ypaqhMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/index.vue?macro=true";
import { default as indexCWoyGf2jaaMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/mytickets/index.vue?macro=true";
import { default as newDbLVyRcgxbMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/mytickets/new.vue?macro=true";
import { default as indexPzqXIaZo1iMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/notifications/index.vue?macro=true";
import { default as indexL8CEwXSxq6Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/profile/index.vue?macro=true";
import { default as indexLZq48L9hYnMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/security/index.vue?macro=true";
import { default as cloudsignoWouMrt3SeMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue?macro=true";
import { default as googleChatmksZchitnrMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/settings/googleChat.vue?macro=true";
import { default as notificationsZNiGixNn9hMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/settings/notifications.vue?macro=true";
import { default as tokensZ0qbJIewfXMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/settings/tokens.vue?macro=true";
import { default as indexWIC4dIsK01Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tasks/index.vue?macro=true";
import { default as editQvEz7LdnTiMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue?macro=true";
import { default as editByApprovervYtXpHEVRZMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue?macro=true";
import { default as indexlgMhh3MwlJMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue?macro=true";
import { default as printqw0HXsbuyMMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue?macro=true";
import { default as index44xfndyhLjMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/index.vue?macro=true";
import { default as indexTw3aOlP6FYMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/view/index.vue?macro=true";
import { default as editfBlubuyliqMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue?macro=true";
import { default as indexNpyrSjjoYQMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/view/settings/index.vue?macro=true";
import { default as newUW1w7cueCLMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/view/settings/new.vue?macro=true";
import { default as searchuzrUFy7hqnMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/workflows/search.vue?macro=true";
import { default as dashboardE1GzNxPyE0Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard.vue?macro=true";
import { default as expiredvZL6OqVwvGMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/expired.vue?macro=true";
import { default as indexkaGGdfWXIXMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/files/[id]/index.vue?macro=true";
import { default as indexGKswVtH772Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/index.vue?macro=true";
import { default as _91id_932vw7YoNcVpMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/invitations/[id].vue?macro=true";
import { default as misocak1tP9VJIK8Meta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/misoca.vue?macro=true";
import { default as redirectQoe4TQ6qdXMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/redirect.vue?macro=true";
import { default as select8YiH6TtfbyMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/select.vue?macro=true";
import { default as signin4qbPWJmnDUMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/signin.vue?macro=true";
import { default as startSsoKzU4v8dlwsMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/startSso.vue?macro=true";
import { default as welcomekcDeWfiPIKMeta } from "/tmp/build_1fe1e3b8/frontend_nuxt3/pages/welcome.vue?macro=true";
export default [
  {
    name: adminvXwjZ09MTYMeta?.name,
    path: "/en/admin",
    meta: adminvXwjZ09MTYMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin.vue"),
    children: [
  {
    name: "admin-audit___en",
    path: "audit",
    meta: indexQoAOfSaRtTMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/audit/index.vue")
  },
  {
    name: "admin-authentication___en",
    path: "authentication",
    meta: indexZIxzDHjGQcMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/authentication/index.vue")
  },
  {
    name: "admin-automations-id-edit___en",
    path: "automations/:id()/edit",
    meta: editudpZYr15uJMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/automations/[id]/edit.vue")
  },
  {
    name: "admin-automations___en",
    path: "automations",
    meta: indexRjQ3RO9t6NMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/automations/index.vue")
  },
  {
    name: "admin-automations-new___en",
    path: "automations/new",
    meta: newjlJEuKzO25Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/automations/new.vue")
  },
  {
    name: "admin-billing___en",
    path: "billing",
    meta: indexEEPA7tvgfyMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/billing/index.vue")
  },
  {
    name: "admin-calculationVariables___en",
    path: "calculationVariables",
    meta: indexAr9GkS4E5qMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/calculationVariables/index.vue")
  },
  {
    name: "admin-categories___en",
    path: "categories",
    meta: index9QHlQ8YezHMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/categories/index.vue")
  },
  {
    name: "admin-connections-box-callback___en",
    path: "connections/box/callback",
    meta: callbackeT1ByvkYWYMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/box/callback.vue")
  },
  {
    name: "admin-connections-box___en",
    path: "connections/box",
    meta: indexCGv6xXnoUKMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/box/index.vue")
  },
  {
    name: "admin-connections-box-redirect___en",
    path: "connections/box/redirect",
    meta: redirect6v3qwGDCE4Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/box/redirect.vue")
  },
  {
    name: "admin-connections-chatwork___en",
    path: "connections/chatwork",
    meta: indexkSAN9hAQroMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/chatwork/index.vue")
  },
  {
    name: "admin-connections-climberCloud___en",
    path: "connections/climberCloud",
    meta: indexxCF4cTb7DYMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue")
  },
  {
    name: "admin-connections-googleDrive___en",
    path: "connections/googleDrive",
    meta: indextVEQeGr9xzMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory___en",
    path: "connections/gwsDirectory",
    meta: index6wz8rU9REvMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory-results___en",
    path: "connections/gwsDirectory/results",
    meta: resultskMnZCipeGqMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue")
  },
  {
    name: "admin-connections-lineWorks___en",
    path: "connections/lineWorks",
    meta: indexNQIKe59uHgMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue")
  },
  {
    name: "admin-connections-microsoftTeams-callback___en",
    path: "connections/microsoftTeams/callback",
    meta: callback3KxQyZO3v7Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue")
  },
  {
    name: "admin-connections-microsoftTeams___en",
    path: "connections/microsoftTeams",
    meta: indexYMOrvV22CMMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue")
  },
  {
    name: "admin-connections-serviceAccounts___en",
    path: "connections/serviceAccounts",
    meta: indexGHhfTFgWc2Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue")
  },
  {
    name: "admin-connections-slack-callback___en",
    path: "connections/slack/callback",
    meta: callbackgRiXVODSaRMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/slack/callback.vue")
  },
  {
    name: "admin-connections-slack___en",
    path: "connections/slack",
    meta: indexHnVfEZMujHMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/slack/index.vue")
  },
  {
    name: "admin-connections-slack-redirect___en",
    path: "connections/slack/redirect",
    meta: redirectYcfv1lXfoxMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/slack/redirect.vue")
  },
  {
    name: "admin-connections-webhook-id-events-eventId___en",
    path: "connections/webhook/:id()/events/:eventId()",
    meta: _91eventId_93UJsDX9ooqoMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/webhook/[id]/events/[eventId].vue")
  },
  {
    name: "admin-connections-webhook-id___en",
    path: "connections/webhook/:id()",
    meta: indexYW8aZjuuPHMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/webhook/[id]/index.vue")
  },
  {
    name: "admin-connections-webhook___en",
    path: "connections/webhook",
    meta: indexApqngh1qDNMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/webhook/index.vue")
  },
  {
    name: "admin-folders-id___en",
    path: "folders/:id()",
    meta: _91id_930vLzwoVteFMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/folders/[id].vue")
  },
  {
    name: "admin-folders___en",
    path: "folders",
    meta: indextbAELrepJKMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/folders/index.vue")
  },
  {
    name: "admin-grades___en",
    path: "grades",
    meta: indexL8IzfM05ZdMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/grades/index.vue")
  },
  {
    name: "admin___en",
    path: "",
    meta: indexG8edjbHcybMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/index.vue")
  },
  {
    name: "admin-invitationDomains___en",
    path: "invitationDomains",
    meta: indexpfJgbd2RlvMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/invitationDomains/index.vue")
  },
  {
    name: "admin-invitationSetting___en",
    path: "invitationSetting",
    meta: index5xNEg04ACpMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/invitationSetting/index.vue")
  },
  {
    name: "admin-ip___en",
    path: "ip",
    meta: indexHkDFmn3teOMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/ip/index.vue")
  },
  {
    name: "admin-labels___en",
    path: "labels",
    meta: indexnxLvC8vZMkMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/labels/index.vue")
  },
  {
    name: "admin-masters-id___en",
    path: "masters/:id()",
    meta: indexNNJHwxetAmMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/[id]/index.vue")
  },
  {
    name: "admin-masters-id-items-itemId___en",
    path: "masters/:id()/items/:itemId()",
    meta: indexQxIyJ3bqg0Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/[id]/items/[itemId]/index.vue")
  },
  {
    name: "admin-masters-id-items-new___en",
    path: "masters/:id()/items/new",
    meta: newLtGODvlM6uMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/[id]/items/new.vue")
  },
  {
    name: "admin-masters-id-workflows___en",
    path: "masters/:id()/workflows",
    meta: workflowsBrQW93neQXMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue")
  },
  {
    name: "admin-masters___en",
    path: "masters",
    meta: indexOyN17VtSkaMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/index.vue")
  },
  {
    name: "admin-numberingGroups-id-workflows___en",
    path: "numberingGroups/:id()/workflows",
    meta: workflowsTUNLu2CLIzMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/numberingGroups/[id]/workflows.vue")
  },
  {
    name: "admin-numberingGroups___en",
    path: "numberingGroups",
    meta: indexJT2NWyCVRAMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/numberingGroups/index.vue")
  },
  {
    name: "admin-organizations-org-compare___en",
    path: "organizations/:org()/compare",
    meta: indexA1qxQwSnUaMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue")
  },
  {
    name: "admin-organizations-org-csv___en",
    path: "organizations/:org()/csv",
    meta: csvSaTRj4bVlwMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue")
  },
  {
    name: "admin-organizations-org___en",
    path: "organizations/:org()",
    meta: indexryLEX5KDUMMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/[org]/index.vue")
  },
  {
    name: "admin-organizations-org-teams-id___en",
    path: "organizations/:org()/teams/:id()",
    meta: _91id_93aDWGVCbOnbMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue")
  },
  {
    name: "admin-organizations-history___en",
    path: "organizations/history",
    meta: historyCth25lDZavMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/history.vue")
  },
  {
    name: "admin-organizations___en",
    path: "organizations",
    meta: indexDSQGZ2OvZYMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/index.vue")
  },
  {
    name: "admin-pipelines-id___en",
    path: "pipelines/:id()",
    meta: _91id_93fCE02gthIFMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/pipelines/[id].vue")
  },
  {
    name: "admin-pipelines___en",
    path: "pipelines",
    meta: indexmhInExbow7Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/pipelines/index.vue")
  },
  {
    name: "admin-pipelines-new___en",
    path: "pipelines/new",
    meta: newE5lRNAqdkWMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/pipelines/new.vue")
  },
  {
    name: "admin-proxyApplicants___en",
    path: "proxyApplicants",
    meta: indexTu6E37hYJ5Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/proxyApplicants/index.vue")
  },
  {
    name: "admin-proxyApprovers___en",
    path: "proxyApprovers",
    meta: indexswn1nqTT6xMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/proxyApprovers/index.vue")
  },
  {
    name: "admin-roles-id___en",
    path: "roles/:id()",
    meta: _91id_93A29Ksh3ThPMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/roles/[id].vue")
  },
  {
    name: "admin-roles___en",
    path: "roles",
    meta: indexHNl4CuaEqwMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/roles/index.vue")
  },
  {
    name: "admin-routes-id-edit___en",
    path: "routes/:id()/edit",
    meta: editb4oX6ktZIxMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/edit.vue")
  },
  {
    name: "admin-routes-id___en",
    path: "routes/:id()",
    meta: indexeBV0FoEZA9Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/index.vue")
  },
  {
    name: "admin-routes-id-simulator___en",
    path: "routes/:id()/simulator",
    meta: indexGfTWhDwbHwMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue")
  },
  {
    name: "admin-routes-id-versions-versionNumber___en",
    path: "routes/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93CmG2VoyNIsMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-routes-id-versions___en",
    path: "routes/:id()/versions",
    meta: indexUhHHKYk0cTMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue")
  },
  {
    name: "admin-routes___en",
    path: "routes",
    meta: indexejT6rTsjzgMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/index.vue")
  },
  {
    name: "admin-routes-new___en",
    path: "routes/new",
    meta: newT8Yo1xqAryMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/new.vue")
  },
  {
    name: "admin-stats___en",
    path: "stats",
    meta: indexTwPmSZahDlMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/stats/index.vue")
  },
  {
    name: "admin-subStatuses___en",
    path: "subStatuses",
    meta: indexsQBZnLZBSIMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/subStatuses/index.vue")
  },
  {
    name: "admin-tenant-assistants___en",
    path: "tenant/assistants",
    meta: assistantsIshp9DFXvWMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/assistants.vue")
  },
  {
    name: "admin-tenant-backups___en",
    path: "tenant/backups",
    meta: backupsfIC3ohmnJFMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/backups.vue")
  },
  {
    name: "admin-tenant___en",
    path: "tenant",
    meta: indexvaZUitJNzaMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/index.vue")
  },
  {
    name: "admin-tenant-news___en",
    path: "tenant/news",
    meta: newsXoSy8Hh5MrMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/news.vue")
  },
  {
    name: "admin-tenant-notifications___en",
    path: "tenant/notifications",
    meta: notificationsc2RNmZclGrMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/notifications.vue")
  },
  {
    name: "admin-tenant-remind___en",
    path: "tenant/remind",
    meta: remindO54VDrc4OgMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/remind.vue")
  },
  {
    name: "admin-tenant-settings___en",
    path: "tenant/settings",
    meta: settingsDJd9OLsqaSMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/settings.vue")
  },
  {
    name: "admin-tenant-subdomain___en",
    path: "tenant/subdomain",
    meta: subdomainAFdz7rtkhwMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/subdomain.vue")
  },
  {
    name: "admin-tenant-working___en",
    path: "tenant/working",
    meta: workingOhbdZw8HQyMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/working.vue")
  },
  {
    name: "admin-tickets-clean___en",
    path: "tickets/clean",
    meta: cleantPfU2FQWPXMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/clean.vue")
  },
  {
    name: "admin-tickets-transfer-myticket___en",
    path: "tickets/transfer/myticket",
    meta: myticketdlVjSfNOkZMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue")
  },
  {
    name: "admin-tickets-transfer-task___en",
    path: "tickets/transfer/task",
    meta: taskZWecsoBiMjMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/transfer/task.vue")
  },
  {
    name: "admin-tickets-viewer-delete___en",
    path: "tickets/viewer/delete",
    meta: deleteYqFzIgDL0OMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/viewer/delete.vue")
  },
  {
    name: "admin-tickets-viewer___en",
    path: "tickets/viewer",
    meta: indexpDLdoMVHOZMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/viewer/index.vue")
  },
  {
    name: "admin-users-id___en",
    path: "users/:id()",
    meta: _91id_93zBo6ft3Sl7Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users-csv___en",
    path: "users/csv",
    meta: csv9j10bvuCWbMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/csv.vue")
  },
  {
    name: "admin-users___en",
    path: "users",
    meta: indexAWhn9ZYTKlMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/index.vue")
  },
  {
    name: "admin-users-invite___en",
    path: "users/invite",
    meta: inviteqNPLV7ZU01Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/invite.vue")
  },
  {
    name: "admin-users-unaffiliated___en",
    path: "users/unaffiliated",
    meta: unaffiliatedojbM0d1w3fMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/unaffiliated.vue")
  },
  {
    name: "admin-workflows-id-edit___en",
    path: "workflows/:id()/edit",
    meta: edit5KH0Pcaie1Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue")
  },
  {
    name: "admin-workflows-id___en",
    path: "workflows/:id()",
    meta: indexLXpLwCP2uMMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/[id]/index.vue")
  },
  {
    name: "admin-workflows-id-versions-versionNumber___en",
    path: "workflows/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93Izqy9ejTqOMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-workflows-id-versions___en",
    path: "workflows/:id()/versions",
    meta: indexz75LwZYRePMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue")
  },
  {
    name: "admin-workflows___en",
    path: "workflows",
    meta: indexIzjgq0sN16Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/index.vue")
  },
  {
    name: "admin-workflows-new___en",
    path: "workflows/new",
    meta: newPhTRj653HWMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/new.vue")
  }
]
  },
  {
    name: adminvXwjZ09MTYMeta?.name,
    path: "/admin",
    meta: adminvXwjZ09MTYMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin.vue"),
    children: [
  {
    name: "admin-audit___ja",
    path: "audit",
    meta: indexQoAOfSaRtTMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/audit/index.vue")
  },
  {
    name: "admin-authentication___ja",
    path: "authentication",
    meta: indexZIxzDHjGQcMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/authentication/index.vue")
  },
  {
    name: "admin-automations-id-edit___ja",
    path: "automations/:id()/edit",
    meta: editudpZYr15uJMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/automations/[id]/edit.vue")
  },
  {
    name: "admin-automations___ja",
    path: "automations",
    meta: indexRjQ3RO9t6NMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/automations/index.vue")
  },
  {
    name: "admin-automations-new___ja",
    path: "automations/new",
    meta: newjlJEuKzO25Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/automations/new.vue")
  },
  {
    name: "admin-billing___ja",
    path: "billing",
    meta: indexEEPA7tvgfyMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/billing/index.vue")
  },
  {
    name: "admin-calculationVariables___ja",
    path: "calculationVariables",
    meta: indexAr9GkS4E5qMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/calculationVariables/index.vue")
  },
  {
    name: "admin-categories___ja",
    path: "categories",
    meta: index9QHlQ8YezHMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/categories/index.vue")
  },
  {
    name: "admin-connections-box-callback___ja",
    path: "connections/box/callback",
    meta: callbackeT1ByvkYWYMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/box/callback.vue")
  },
  {
    name: "admin-connections-box___ja",
    path: "connections/box",
    meta: indexCGv6xXnoUKMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/box/index.vue")
  },
  {
    name: "admin-connections-box-redirect___ja",
    path: "connections/box/redirect",
    meta: redirect6v3qwGDCE4Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/box/redirect.vue")
  },
  {
    name: "admin-connections-chatwork___ja",
    path: "connections/chatwork",
    meta: indexkSAN9hAQroMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/chatwork/index.vue")
  },
  {
    name: "admin-connections-climberCloud___ja",
    path: "connections/climberCloud",
    meta: indexxCF4cTb7DYMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue")
  },
  {
    name: "admin-connections-googleDrive___ja",
    path: "connections/googleDrive",
    meta: indextVEQeGr9xzMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory___ja",
    path: "connections/gwsDirectory",
    meta: index6wz8rU9REvMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory-results___ja",
    path: "connections/gwsDirectory/results",
    meta: resultskMnZCipeGqMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue")
  },
  {
    name: "admin-connections-lineWorks___ja",
    path: "connections/lineWorks",
    meta: indexNQIKe59uHgMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue")
  },
  {
    name: "admin-connections-microsoftTeams-callback___ja",
    path: "connections/microsoftTeams/callback",
    meta: callback3KxQyZO3v7Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue")
  },
  {
    name: "admin-connections-microsoftTeams___ja",
    path: "connections/microsoftTeams",
    meta: indexYMOrvV22CMMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue")
  },
  {
    name: "admin-connections-serviceAccounts___ja",
    path: "connections/serviceAccounts",
    meta: indexGHhfTFgWc2Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue")
  },
  {
    name: "admin-connections-slack-callback___ja",
    path: "connections/slack/callback",
    meta: callbackgRiXVODSaRMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/slack/callback.vue")
  },
  {
    name: "admin-connections-slack___ja",
    path: "connections/slack",
    meta: indexHnVfEZMujHMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/slack/index.vue")
  },
  {
    name: "admin-connections-slack-redirect___ja",
    path: "connections/slack/redirect",
    meta: redirectYcfv1lXfoxMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/slack/redirect.vue")
  },
  {
    name: "admin-connections-webhook-id-events-eventId___ja",
    path: "connections/webhook/:id()/events/:eventId()",
    meta: _91eventId_93UJsDX9ooqoMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/webhook/[id]/events/[eventId].vue")
  },
  {
    name: "admin-connections-webhook-id___ja",
    path: "connections/webhook/:id()",
    meta: indexYW8aZjuuPHMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/webhook/[id]/index.vue")
  },
  {
    name: "admin-connections-webhook___ja",
    path: "connections/webhook",
    meta: indexApqngh1qDNMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/connections/webhook/index.vue")
  },
  {
    name: "admin-folders-id___ja",
    path: "folders/:id()",
    meta: _91id_930vLzwoVteFMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/folders/[id].vue")
  },
  {
    name: "admin-folders___ja",
    path: "folders",
    meta: indextbAELrepJKMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/folders/index.vue")
  },
  {
    name: "admin-grades___ja",
    path: "grades",
    meta: indexL8IzfM05ZdMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/grades/index.vue")
  },
  {
    name: "admin___ja",
    path: "",
    meta: indexG8edjbHcybMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/index.vue")
  },
  {
    name: "admin-invitationDomains___ja",
    path: "invitationDomains",
    meta: indexpfJgbd2RlvMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/invitationDomains/index.vue")
  },
  {
    name: "admin-invitationSetting___ja",
    path: "invitationSetting",
    meta: index5xNEg04ACpMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/invitationSetting/index.vue")
  },
  {
    name: "admin-ip___ja",
    path: "ip",
    meta: indexHkDFmn3teOMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/ip/index.vue")
  },
  {
    name: "admin-labels___ja",
    path: "labels",
    meta: indexnxLvC8vZMkMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/labels/index.vue")
  },
  {
    name: "admin-masters-id___ja",
    path: "masters/:id()",
    meta: indexNNJHwxetAmMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/[id]/index.vue")
  },
  {
    name: "admin-masters-id-items-itemId___ja",
    path: "masters/:id()/items/:itemId()",
    meta: indexQxIyJ3bqg0Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/[id]/items/[itemId]/index.vue")
  },
  {
    name: "admin-masters-id-items-new___ja",
    path: "masters/:id()/items/new",
    meta: newLtGODvlM6uMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/[id]/items/new.vue")
  },
  {
    name: "admin-masters-id-workflows___ja",
    path: "masters/:id()/workflows",
    meta: workflowsBrQW93neQXMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue")
  },
  {
    name: "admin-masters___ja",
    path: "masters",
    meta: indexOyN17VtSkaMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/masters/index.vue")
  },
  {
    name: "admin-numberingGroups-id-workflows___ja",
    path: "numberingGroups/:id()/workflows",
    meta: workflowsTUNLu2CLIzMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/numberingGroups/[id]/workflows.vue")
  },
  {
    name: "admin-numberingGroups___ja",
    path: "numberingGroups",
    meta: indexJT2NWyCVRAMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/numberingGroups/index.vue")
  },
  {
    name: "admin-organizations-org-compare___ja",
    path: "organizations/:org()/compare",
    meta: indexA1qxQwSnUaMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue")
  },
  {
    name: "admin-organizations-org-csv___ja",
    path: "organizations/:org()/csv",
    meta: csvSaTRj4bVlwMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue")
  },
  {
    name: "admin-organizations-org___ja",
    path: "organizations/:org()",
    meta: indexryLEX5KDUMMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/[org]/index.vue")
  },
  {
    name: "admin-organizations-org-teams-id___ja",
    path: "organizations/:org()/teams/:id()",
    meta: _91id_93aDWGVCbOnbMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue")
  },
  {
    name: "admin-organizations-history___ja",
    path: "organizations/history",
    meta: historyCth25lDZavMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/history.vue")
  },
  {
    name: "admin-organizations___ja",
    path: "organizations",
    meta: indexDSQGZ2OvZYMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/organizations/index.vue")
  },
  {
    name: "admin-pipelines-id___ja",
    path: "pipelines/:id()",
    meta: _91id_93fCE02gthIFMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/pipelines/[id].vue")
  },
  {
    name: "admin-pipelines___ja",
    path: "pipelines",
    meta: indexmhInExbow7Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/pipelines/index.vue")
  },
  {
    name: "admin-pipelines-new___ja",
    path: "pipelines/new",
    meta: newE5lRNAqdkWMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/pipelines/new.vue")
  },
  {
    name: "admin-proxyApplicants___ja",
    path: "proxyApplicants",
    meta: indexTu6E37hYJ5Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/proxyApplicants/index.vue")
  },
  {
    name: "admin-proxyApprovers___ja",
    path: "proxyApprovers",
    meta: indexswn1nqTT6xMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/proxyApprovers/index.vue")
  },
  {
    name: "admin-roles-id___ja",
    path: "roles/:id()",
    meta: _91id_93A29Ksh3ThPMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/roles/[id].vue")
  },
  {
    name: "admin-roles___ja",
    path: "roles",
    meta: indexHNl4CuaEqwMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/roles/index.vue")
  },
  {
    name: "admin-routes-id-edit___ja",
    path: "routes/:id()/edit",
    meta: editb4oX6ktZIxMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/edit.vue")
  },
  {
    name: "admin-routes-id___ja",
    path: "routes/:id()",
    meta: indexeBV0FoEZA9Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/index.vue")
  },
  {
    name: "admin-routes-id-simulator___ja",
    path: "routes/:id()/simulator",
    meta: indexGfTWhDwbHwMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue")
  },
  {
    name: "admin-routes-id-versions-versionNumber___ja",
    path: "routes/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93CmG2VoyNIsMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-routes-id-versions___ja",
    path: "routes/:id()/versions",
    meta: indexUhHHKYk0cTMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue")
  },
  {
    name: "admin-routes___ja",
    path: "routes",
    meta: indexejT6rTsjzgMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/index.vue")
  },
  {
    name: "admin-routes-new___ja",
    path: "routes/new",
    meta: newT8Yo1xqAryMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/routes/new.vue")
  },
  {
    name: "admin-stats___ja",
    path: "stats",
    meta: indexTwPmSZahDlMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/stats/index.vue")
  },
  {
    name: "admin-subStatuses___ja",
    path: "subStatuses",
    meta: indexsQBZnLZBSIMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/subStatuses/index.vue")
  },
  {
    name: "admin-tenant-assistants___ja",
    path: "tenant/assistants",
    meta: assistantsIshp9DFXvWMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/assistants.vue")
  },
  {
    name: "admin-tenant-backups___ja",
    path: "tenant/backups",
    meta: backupsfIC3ohmnJFMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/backups.vue")
  },
  {
    name: "admin-tenant___ja",
    path: "tenant",
    meta: indexvaZUitJNzaMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/index.vue")
  },
  {
    name: "admin-tenant-news___ja",
    path: "tenant/news",
    meta: newsXoSy8Hh5MrMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/news.vue")
  },
  {
    name: "admin-tenant-notifications___ja",
    path: "tenant/notifications",
    meta: notificationsc2RNmZclGrMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/notifications.vue")
  },
  {
    name: "admin-tenant-remind___ja",
    path: "tenant/remind",
    meta: remindO54VDrc4OgMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/remind.vue")
  },
  {
    name: "admin-tenant-settings___ja",
    path: "tenant/settings",
    meta: settingsDJd9OLsqaSMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/settings.vue")
  },
  {
    name: "admin-tenant-subdomain___ja",
    path: "tenant/subdomain",
    meta: subdomainAFdz7rtkhwMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/subdomain.vue")
  },
  {
    name: "admin-tenant-working___ja",
    path: "tenant/working",
    meta: workingOhbdZw8HQyMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tenant/working.vue")
  },
  {
    name: "admin-tickets-clean___ja",
    path: "tickets/clean",
    meta: cleantPfU2FQWPXMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/clean.vue")
  },
  {
    name: "admin-tickets-transfer-myticket___ja",
    path: "tickets/transfer/myticket",
    meta: myticketdlVjSfNOkZMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue")
  },
  {
    name: "admin-tickets-transfer-task___ja",
    path: "tickets/transfer/task",
    meta: taskZWecsoBiMjMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/transfer/task.vue")
  },
  {
    name: "admin-tickets-viewer-delete___ja",
    path: "tickets/viewer/delete",
    meta: deleteYqFzIgDL0OMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/viewer/delete.vue")
  },
  {
    name: "admin-tickets-viewer___ja",
    path: "tickets/viewer",
    meta: indexpDLdoMVHOZMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/tickets/viewer/index.vue")
  },
  {
    name: "admin-users-id___ja",
    path: "users/:id()",
    meta: _91id_93zBo6ft3Sl7Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users-csv___ja",
    path: "users/csv",
    meta: csv9j10bvuCWbMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/csv.vue")
  },
  {
    name: "admin-users___ja",
    path: "users",
    meta: indexAWhn9ZYTKlMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/index.vue")
  },
  {
    name: "admin-users-invite___ja",
    path: "users/invite",
    meta: inviteqNPLV7ZU01Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/invite.vue")
  },
  {
    name: "admin-users-unaffiliated___ja",
    path: "users/unaffiliated",
    meta: unaffiliatedojbM0d1w3fMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/users/unaffiliated.vue")
  },
  {
    name: "admin-workflows-id-edit___ja",
    path: "workflows/:id()/edit",
    meta: edit5KH0Pcaie1Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue")
  },
  {
    name: "admin-workflows-id___ja",
    path: "workflows/:id()",
    meta: indexLXpLwCP2uMMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/[id]/index.vue")
  },
  {
    name: "admin-workflows-id-versions-versionNumber___ja",
    path: "workflows/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93Izqy9ejTqOMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-workflows-id-versions___ja",
    path: "workflows/:id()/versions",
    meta: indexz75LwZYRePMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue")
  },
  {
    name: "admin-workflows___ja",
    path: "workflows",
    meta: indexIzjgq0sN16Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/index.vue")
  },
  {
    name: "admin-workflows-new___ja",
    path: "workflows/new",
    meta: newPhTRj653HWMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/admin/workflows/new.vue")
  }
]
  },
  {
    name: "assistants-id-signin___en",
    path: "/en/assistants/:id()/signin",
    meta: signinecNiUfAXJUMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/assistants/[id]/signin.vue")
  },
  {
    name: "assistants-id-signin___ja",
    path: "/assistants/:id()/signin",
    meta: signinecNiUfAXJUMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/assistants/[id]/signin.vue")
  },
  {
    name: "auth-callback___en",
    path: "/en/auth/callback",
    meta: callback4LBHbfgW5CMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/auth/callback.vue")
  },
  {
    name: "auth-callback___ja",
    path: "/auth/callback",
    meta: callback4LBHbfgW5CMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/auth/callback.vue")
  },
  {
    name: "auth-callback2___en",
    path: "/en/auth/callback2",
    meta: callback22TTCtJI1BJMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/auth/callback2.vue")
  },
  {
    name: "auth-callback2___ja",
    path: "/auth/callback2",
    meta: callback22TTCtJI1BJMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/auth/callback2.vue")
  },
  {
    name: "auth-googleCallback2___en",
    path: "/en/auth/googleCallback2",
    meta: googleCallback2OA0uvHGPFgMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/auth/googleCallback2.vue")
  },
  {
    name: "auth-googleCallback2___ja",
    path: "/auth/googleCallback2",
    meta: googleCallback2OA0uvHGPFgMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/auth/googleCallback2.vue")
  },
  {
    name: "auth-verify_email___en",
    path: "/en/auth/verify_email",
    meta: verify_email2h48JkkMQMMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/auth/verify_email.vue")
  },
  {
    name: "auth-verify_email___ja",
    path: "/auth/verify_email",
    meta: verify_email2h48JkkMQMMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/auth/verify_email.vue")
  },
  {
    name: dashboardE1GzNxPyE0Meta?.name,
    path: "/en/dashboard",
    meta: dashboardE1GzNxPyE0Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard___en",
    path: "",
    meta: indexaenk5ypaqhMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-mytickets___en",
    path: "mytickets",
    meta: indexCWoyGf2jaaMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/mytickets/index.vue")
  },
  {
    name: "dashboard-mytickets-new___en",
    path: "mytickets/new",
    meta: newDbLVyRcgxbMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/mytickets/new.vue")
  },
  {
    name: "dashboard-notifications___en",
    path: "notifications",
    meta: indexPzqXIaZo1iMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/notifications/index.vue")
  },
  {
    name: "dashboard-profile___en",
    path: "profile",
    meta: indexL8CEwXSxq6Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-security___en",
    path: "security",
    meta: indexLZq48L9hYnMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/security/index.vue")
  },
  {
    name: "dashboard-settings-cloudsign___en",
    path: "settings/cloudsign",
    meta: cloudsignoWouMrt3SeMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue")
  },
  {
    name: "dashboard-settings-googleChat___en",
    path: "settings/googleChat",
    meta: googleChatmksZchitnrMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/settings/googleChat.vue")
  },
  {
    name: "dashboard-settings-notifications___en",
    path: "settings/notifications",
    meta: notificationsZNiGixNn9hMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/settings/notifications.vue")
  },
  {
    name: "dashboard-settings-tokens___en",
    path: "settings/tokens",
    meta: tokensZ0qbJIewfXMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/settings/tokens.vue")
  },
  {
    name: "dashboard-tasks___en",
    path: "tasks",
    meta: indexWIC4dIsK01Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tasks/index.vue")
  },
  {
    name: "dashboard-tickets-id-edit___en",
    path: "tickets/:id()/edit",
    meta: editQvEz7LdnTiMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue")
  },
  {
    name: "dashboard-tickets-id-editByApprover___en",
    path: "tickets/:id()/editByApprover",
    meta: editByApprovervYtXpHEVRZMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue")
  },
  {
    name: "dashboard-tickets-id___en",
    path: "tickets/:id()",
    meta: indexlgMhh3MwlJMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue")
  },
  {
    name: "dashboard-tickets-id-print___en",
    path: "tickets/:id()/print",
    meta: printqw0HXsbuyMMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue")
  },
  {
    name: "dashboard-tickets___en",
    path: "tickets",
    meta: index44xfndyhLjMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/index.vue")
  },
  {
    name: "dashboard-view___en",
    path: "view",
    meta: indexTw3aOlP6FYMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/view/index.vue")
  },
  {
    name: "dashboard-view-settings-id-edit___en",
    path: "view/settings/:id()/edit",
    meta: editfBlubuyliqMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue")
  },
  {
    name: "dashboard-view-settings___en",
    path: "view/settings",
    meta: indexNpyrSjjoYQMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/view/settings/index.vue")
  },
  {
    name: "dashboard-view-settings-new___en",
    path: "view/settings/new",
    meta: newUW1w7cueCLMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/view/settings/new.vue")
  },
  {
    name: "dashboard-workflows-search___en",
    path: "workflows/search",
    meta: searchuzrUFy7hqnMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/workflows/search.vue")
  }
]
  },
  {
    name: dashboardE1GzNxPyE0Meta?.name,
    path: "/dashboard",
    meta: dashboardE1GzNxPyE0Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard___ja",
    path: "",
    meta: indexaenk5ypaqhMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-mytickets___ja",
    path: "mytickets",
    meta: indexCWoyGf2jaaMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/mytickets/index.vue")
  },
  {
    name: "dashboard-mytickets-new___ja",
    path: "mytickets/new",
    meta: newDbLVyRcgxbMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/mytickets/new.vue")
  },
  {
    name: "dashboard-notifications___ja",
    path: "notifications",
    meta: indexPzqXIaZo1iMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/notifications/index.vue")
  },
  {
    name: "dashboard-profile___ja",
    path: "profile",
    meta: indexL8CEwXSxq6Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-security___ja",
    path: "security",
    meta: indexLZq48L9hYnMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/security/index.vue")
  },
  {
    name: "dashboard-settings-cloudsign___ja",
    path: "settings/cloudsign",
    meta: cloudsignoWouMrt3SeMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue")
  },
  {
    name: "dashboard-settings-googleChat___ja",
    path: "settings/googleChat",
    meta: googleChatmksZchitnrMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/settings/googleChat.vue")
  },
  {
    name: "dashboard-settings-notifications___ja",
    path: "settings/notifications",
    meta: notificationsZNiGixNn9hMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/settings/notifications.vue")
  },
  {
    name: "dashboard-settings-tokens___ja",
    path: "settings/tokens",
    meta: tokensZ0qbJIewfXMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/settings/tokens.vue")
  },
  {
    name: "dashboard-tasks___ja",
    path: "tasks",
    meta: indexWIC4dIsK01Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tasks/index.vue")
  },
  {
    name: "dashboard-tickets-id-edit___ja",
    path: "tickets/:id()/edit",
    meta: editQvEz7LdnTiMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue")
  },
  {
    name: "dashboard-tickets-id-editByApprover___ja",
    path: "tickets/:id()/editByApprover",
    meta: editByApprovervYtXpHEVRZMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue")
  },
  {
    name: "dashboard-tickets-id___ja",
    path: "tickets/:id()",
    meta: indexlgMhh3MwlJMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue")
  },
  {
    name: "dashboard-tickets-id-print___ja",
    path: "tickets/:id()/print",
    meta: printqw0HXsbuyMMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue")
  },
  {
    name: "dashboard-tickets___ja",
    path: "tickets",
    meta: index44xfndyhLjMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/tickets/index.vue")
  },
  {
    name: "dashboard-view___ja",
    path: "view",
    meta: indexTw3aOlP6FYMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/view/index.vue")
  },
  {
    name: "dashboard-view-settings-id-edit___ja",
    path: "view/settings/:id()/edit",
    meta: editfBlubuyliqMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue")
  },
  {
    name: "dashboard-view-settings___ja",
    path: "view/settings",
    meta: indexNpyrSjjoYQMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/view/settings/index.vue")
  },
  {
    name: "dashboard-view-settings-new___ja",
    path: "view/settings/new",
    meta: newUW1w7cueCLMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/view/settings/new.vue")
  },
  {
    name: "dashboard-workflows-search___ja",
    path: "workflows/search",
    meta: searchuzrUFy7hqnMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/dashboard/workflows/search.vue")
  }
]
  },
  {
    name: "expired___en",
    path: "/en/expired",
    meta: expiredvZL6OqVwvGMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/expired.vue")
  },
  {
    name: "expired___ja",
    path: "/expired",
    meta: expiredvZL6OqVwvGMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/expired.vue")
  },
  {
    name: "files-id___en",
    path: "/en/files/:id()",
    meta: indexkaGGdfWXIXMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/files/[id]/index.vue")
  },
  {
    name: "files-id___ja",
    path: "/files/:id()",
    meta: indexkaGGdfWXIXMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/files/[id]/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexGKswVtH772Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/index.vue")
  },
  {
    name: "index___ja",
    path: "/",
    meta: indexGKswVtH772Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/index.vue")
  },
  {
    name: "invitations-id___en",
    path: "/en/invitations/:id()",
    meta: _91id_932vw7YoNcVpMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/invitations/[id].vue")
  },
  {
    name: "invitations-id___ja",
    path: "/invitations/:id()",
    meta: _91id_932vw7YoNcVpMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/invitations/[id].vue")
  },
  {
    name: "misoca___en",
    path: "/en/misoca",
    meta: misocak1tP9VJIK8Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/misoca.vue")
  },
  {
    name: "misoca___ja",
    path: "/misoca",
    meta: misocak1tP9VJIK8Meta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/misoca.vue")
  },
  {
    name: "redirect___en",
    path: "/en/redirect",
    meta: redirectQoe4TQ6qdXMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/redirect.vue")
  },
  {
    name: "redirect___ja",
    path: "/redirect",
    meta: redirectQoe4TQ6qdXMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/redirect.vue")
  },
  {
    name: "select___en",
    path: "/en/select",
    meta: select8YiH6TtfbyMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/select.vue")
  },
  {
    name: "select___ja",
    path: "/select",
    meta: select8YiH6TtfbyMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/select.vue")
  },
  {
    name: "signin___en",
    path: "/en/signin",
    meta: signin4qbPWJmnDUMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/signin.vue")
  },
  {
    name: "signin___ja",
    path: "/signin",
    meta: signin4qbPWJmnDUMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/signin.vue")
  },
  {
    name: "startSso___en",
    path: "/en/startSso",
    meta: startSsoKzU4v8dlwsMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/startSso.vue")
  },
  {
    name: "startSso___ja",
    path: "/startSso",
    meta: startSsoKzU4v8dlwsMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/startSso.vue")
  },
  {
    name: "welcome___en",
    path: "/en/welcome",
    meta: welcomekcDeWfiPIKMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/welcome.vue")
  },
  {
    name: "welcome___ja",
    path: "/welcome",
    meta: welcomekcDeWfiPIKMeta || {},
    component: () => import("/tmp/build_1fe1e3b8/frontend_nuxt3/pages/welcome.vue")
  }
]
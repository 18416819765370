import { useAuth0Plugin } from '~/composables/auth0'
import type { SortItem } from '~/types/pages/components'
import type { HttpClient } from '~/utils/httpClient'

export class PagingQuery {
  page: number
  perPage: number
  sort: SortItem[]

  constructor(hash: { page?: number; perPage?: number; sort?: SortItem[] }) {
    this.page = hash.page || 1
    this.perPage = hash.perPage || 25
    this.sort = hash.sort || []
  }

  toParams(): { [key: string]: any | any[] } {
    const queryParams: any = {}
    queryParams.page = this.page
    queryParams.perPage = this.perPage
    const sortByParams: string[] = []
    this.sort.forEach((val) => {
      if (val.order === 'desc') {
        sortByParams.push(val.key + '-desc')
      } else if (val.order === 'asc') {
        sortByParams.push(val.key + '-asc')
      }
    })
    if (sortByParams.length > 0) {
      queryParams.sortBy = sortByParams.join(',')
    }
    return queryParams
  }
}

export class PagingList<T> {
  data: T[]
  length: number
  total: number

  constructor(data: T[], length: number, total: number) {
    this.data = data
    this.length = length
    this.total = total
  }
}

/**
 * 各APIクライアントのベースクラス
 */
export abstract class KickflowApi {
  private readonly httpClient: HttpClient
  private accessToken: string | null
  private basicUser: string | null
  private basicPass: string | null

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
    this.accessToken = null
    this.basicUser = null
    this.basicPass = null
  }

  // basic methods

  protected async get<T = any>(
    url: string,
    queryParams?: Record<string, any>,
    headers?: { [key: string]: string | undefined }
  ): Promise<T> {
    await this.reloadAccessToken()
    return await this.httpClient.get(url, queryParams, headers)
  }

  protected async getPagingList<T = any>(
    url: string,
    queryParams?: Record<string, any>,
    headers?: { [key: string]: string | undefined }
  ): Promise<PagingList<T>> {
    await this.reloadAccessToken()

    const response = await this.httpClient.getRaw(url, queryParams, headers)
    return new PagingList<T>(
      response._data,
      KickflowApi.getLengthFromLink(response.headers),
      KickflowApi.getTotalFromLink(response.headers)
    )
  }

  protected async getBlob(
    url: string,
    queryParams?: Record<string, any>,
    headers?: { [key: string]: string | undefined }
  ): Promise<Blob> {
    await this.reloadAccessToken()
    return await this.httpClient.getBlob(url, queryParams, headers)
  }

  protected async delete<T = any>(
    url: string,
    queryParams?: Record<string, any>,
    headers?: { [key: string]: string | undefined }
  ): Promise<T> {
    await this.reloadAccessToken()
    return await this.httpClient.delete(url, queryParams, headers)
  }

  protected async post<T = any>(
    url: string,
    params?: any,
    headers?: { [key: string]: string | undefined }
  ): Promise<T> {
    await this.reloadAccessToken()
    return await this.httpClient.post(url, params, headers)
  }

  protected async put<T = any>(
    url: string,
    params?: any,
    headers?: { [key: string]: string | undefined }
  ): Promise<T> {
    await this.reloadAccessToken()
    return await this.httpClient.put(url, params, headers)
  }

  protected async patch<T = any>(
    url: string,
    params?: any,
    headers?: { [key: string]: string | undefined }
  ): Promise<T> {
    await this.reloadAccessToken()
    return await this.httpClient.patch(url, params, headers)
  }

  private static getLengthFromLink(headers: Headers): number {
    const total = parseInt(headers.get('total') || '')
    const perPage = parseInt(headers.get('per-page') || '')
    return Math.ceil(total / perPage)
  }

  private static getTotalFromLink(headers: Headers): number {
    return parseInt(headers.get('total') || '')
  }

  /**
   * アクセストークンを再取得する
   * アクセストークンが失効しているがセッションタイムアウト前であれば、ここでアクセストークンが再取得される
   * @private
   */
  private async reloadAccessToken() {
    try {
      const auth0 = useAuth0Plugin()
      this.accessToken = await auth0.getAccessTokenSilently()
      this.httpClient.setHeader('X-Authorization', 'Bearer ' + this.accessToken)
    } catch {
      // do nothing
    }
  }
}

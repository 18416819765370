import payload_plugin_qLmFnukI99 from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/tmp/build_1fe1e3b8/frontend_nuxt3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/tmp/build_1fe1e3b8/frontend_nuxt3/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_AUP22rrBAc from "/tmp/build_1fe1e3b8/frontend_nuxt3/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _01_consola_24TMZcGF6A from "/tmp/build_1fe1e3b8/frontend_nuxt3/plugins/01.consola.ts";
import _02_vuetify_rwZ6VQqJQJ from "/tmp/build_1fe1e3b8/frontend_nuxt3/plugins/02.vuetify.ts";
import _03_dayjs_xZquBqOx7l from "/tmp/build_1fe1e3b8/frontend_nuxt3/plugins/03.dayjs.ts";
import _04_httpClient_U7tCWrFbrg from "/tmp/build_1fe1e3b8/frontend_nuxt3/plugins/04.httpClient.ts";
import _07_markdownit_FeFs9GstEw from "/tmp/build_1fe1e3b8/frontend_nuxt3/plugins/07.markdownit.ts";
import _08_kickflowApi_MLfrrg2sBY from "/tmp/build_1fe1e3b8/frontend_nuxt3/plugins/08.kickflowApi.ts";
import _09_snackbar_4Lcdm2twDe from "/tmp/build_1fe1e3b8/frontend_nuxt3/plugins/09.snackbar.ts";
import _10_auth0_client_edhMsHIEBq from "/tmp/build_1fe1e3b8/frontend_nuxt3/plugins/10.auth0.client.ts";
import _11_uploader_client_fjqwwoj2ZV from "/tmp/build_1fe1e3b8/frontend_nuxt3/plugins/11.uploader.client.ts";
import _13_zendesk_client_b07Mfk1iXM from "/tmp/build_1fe1e3b8/frontend_nuxt3/plugins/13.zendesk.client.ts";
import _14_sentry_client_54a9c4sM6T from "/tmp/build_1fe1e3b8/frontend_nuxt3/plugins/14.sentry.client.ts";
import _15_chart_DevEUiastb from "/tmp/build_1fe1e3b8/frontend_nuxt3/plugins/15.chart.ts";
import _16_firebase_client_w3LrwniIIU from "/tmp/build_1fe1e3b8/frontend_nuxt3/plugins/16.firebase.client.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_AUP22rrBAc,
  _01_consola_24TMZcGF6A,
  _02_vuetify_rwZ6VQqJQJ,
  _03_dayjs_xZquBqOx7l,
  _04_httpClient_U7tCWrFbrg,
  _07_markdownit_FeFs9GstEw,
  _08_kickflowApi_MLfrrg2sBY,
  _09_snackbar_4Lcdm2twDe,
  _10_auth0_client_edhMsHIEBq,
  _11_uploader_client_fjqwwoj2ZV,
  _13_zendesk_client_b07Mfk1iXM,
  _14_sentry_client_54a9c4sM6T,
  _15_chart_DevEUiastb,
  _16_firebase_client_w3LrwniIIU
]
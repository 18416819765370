<template>
  <KApp style="background: white">
    <KMain>
      <KContainer>
        <KContainer>
          <img
            alt="kickflow"
            height="31.188"
            src="/images/logo.svg"
            width="160"
          />
          <PageHeader
            :breadcrumbs="breadcrumbs"
            class="mt-6"
            :title="title || ''"
          />
        </KContainer>
        <slot />
      </KContainer>
    </KMain>
  </KApp>
</template>

<script setup lang="ts">
// 印刷用レイアウト。ミドルウェアはページ側で設定してください。

const route = useRoute()
const i18n = useI18n()

useHead({
  htmlAttrs: {
    lang: i18n.locale,
  },
  link: [
    {
      rel: 'canonical',
      href: getCanonicalBaseUrl() + route.path,
    },
  ],
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
})

const commonUiStore = useCommonUiStore()

const title = computed(() => commonUiStore.header.title)
const breadcrumbs = computed(() => commonUiStore.header.breadcrumbs)

onMounted(async () => {
  await Promise.all([
    (async () => {
      await commonUiStore.refreshTaskAlert()
    })(),
    (async () => {
      await commonUiStore.refreshNotificationAlert()
    })(),
  ])
})
</script>
